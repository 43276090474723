import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'typography-p',
  standalone: true,
  styleUrl: './typography-p.component.scss',
  templateUrl: './typography-p.component.html',
  imports: [CommonModule]
})
export class TypographyPComponent {
  @Input() label?: string;
  @Input() bold = false;
  @Input() p: 'body1' | 'body2' | 'subtitle1' | 'subtitle2' | 'caption' = 'body1';
  @Input() textAlign: 'left' | 'right' | 'center' | 'justify' = 'left';
}
