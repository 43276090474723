// TODO
import { Client } from "../../constants";
import { UserProfileDto } from "../../types";
import { Permissions } from "./permissions.enum";

enum LegacyRoles {
  ADMIN = 'Admin',
  UNLICENSED_USER = 'Unlicensed User',
  //WLGRN
  BROKER = 'Broker',
  TRANSACTION_MANAGER = 'Transaction Manager',
  TRANSACTION_SUPER_USER = 'Transaction Super User',
  //LINK
  DEV_ADMIN = 'Development Admin',
  DEV_MGR_PLUS = 'Development Manager Plus',
  DEV_MGR = 'Development Manager',
  DEV_ACCT_MGR = 'Development Accounting',  
  THIRD_PARTY = 'Third Party',
  INSPECTION_VENDOR = 'Inspection Vendor',
  DEVELOPMENT = 'Development',
  INSPECTION_VENDOR_READ_ONLY = 'Inspection Vendor – Read Only',
  LEASING_MGR = 'Leasing Manager',
  LEASING_ADMIN = 'Leasing Admin',
  LEASE_ADMIN = 'Lease Admin',
  FPA_CASHFLOW = 'FPA Cashflow',
  LEED_PRTNR = 'LEED Partner',
  LEED_MGR = 'LEED Manager',
  REQUEST_INTAKE = 'Request Intake',
  LIMITED_WAG_USER = 'Limited User'
}

export class LegacyPermissions {
  private readonly roles = LegacyRoles;

  getPermissions(
    user: UserProfileDto,
    permissions: string[]
  ): string[] {
    console.log({
      legacy: permissions
    });
    const client = user.userAccountDto?.find(
      (account) => user.accountId === account.accountId
    );
    const roleName = (client?.userRoleDto.roleName ?? 'Guest') as LegacyRoles;
    
    const linkPorfolioNoAccessRoles = [
      this.roles.INSPECTION_VENDOR,
      this.roles.INSPECTION_VENDOR_READ_ONLY
    ];

    const wagPorfolioNoAccessRoles = [
      this.roles.BROKER,
      this.roles.REQUEST_INTAKE
    ];

    const noPropertiesAccess = [
      this.roles.BROKER,
      this.roles.INSPECTION_VENDOR,
      this.roles.INSPECTION_VENDOR_READ_ONLY
    ];

    const hasPortfolioAccess = (
      client?.accountName === Client.LINK && !linkPorfolioNoAccessRoles.includes(roleName)
    ) || (
      client?.accountName === Client.WAG && !wagPorfolioNoAccessRoles.includes(roleName)
    );

    return [
      Permissions.PROPERTY_OVERVIEW_ASSIGNMENTS_VIEW,
      hasPortfolioAccess && Permissions.APP_PERSISTENT_FILTERS,
      (client?.accountName === Client.WAG) && Permissions.APP_DOCUMENT_UPLOAD_LIMIT,
      !noPropertiesAccess.includes(roleName) && Permissions.PROPERTY_VIEW,
      (client?.accountName === Client.LINK) && Permissions.PROPERTY_OVERVIEW_OWNERSHIP_INFO_VIEW,
      (client?.accountName === Client.LINK) && Permissions.PROPERTY_OVERVIEW_MARKET_INFO_VIEW,
      (client?.accountName === Client.LINK) && Permissions.PROPERTY_OVERVIEW_NOTES_VIEW,
      (client?.accountName === Client.LINK) && Permissions.PROPERTY_OVERVIEW_NOTES_ADD, // todo: confirm permission
      (client?.accountName === Client.LINK) && Permissions.PROPERTY_OVERVIEW_NOTES_REPLY, // todo: confirm permission
      (client?.accountName === Client.LINK) && (roleName !== this.roles.THIRD_PARTY) && Permissions.PROPERTY_FINANCIALS_VIEW,
      (client?.accountName === Client.LINK) && (roleName !== this.roles.THIRD_PARTY) && Permissions.PROPERTY_SITE_FINANCIALS_VIEW
    ].filter(permission => !!permission) as string[];
  }
}
