<ng-container [ngSwitch]="layout">
  <ng-container *ngSwitchCase="'default'">
    <login-layout-default
      [logo]="logo"
      [backgroundVideo]="backgroundVideo"
      [currentPhase]="currentPhase"
      [loading]="loading"
      [formValues]="formValues"
      (phaseChange)="handlePhaseChange($event)"
    >
    </login-layout-default>
  </ng-container>
  <ng-container *ngSwitchCase="'holiday'">
    <login-layout-holiday
      [logo]="logo"
      [backgroundVideo]="backgroundVideo"
      [currentPhase]="currentPhase"
      [loading]="loading"
      [formValues]="formValues"
      (phaseChange)="handlePhaseChange($event)"
    >
    </login-layout-holiday>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <login-layout-default
      [logo]="logo"
      [backgroundVideo]="backgroundVideo"
      [currentPhase]="currentPhase"
      [loading]="loading"
      [formValues]="formValues"
      (phaseChange)="handlePhaseChange($event)"
    >
    </login-layout-default>
  </ng-container>
</ng-container>
