import { inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../provider-tokens";
import { HttpService } from "../http.service";
import { HttpParams } from "@angular/common/http";
import {
  BaseHttpErrorDto,
  BaseHttpResponseDto,
  LinkAcrPropertyDto,
  LinkAcrReportResponseDto,
} from "../../types";

@Injectable({
  providedIn: 'root'
})
export class LinkJobApi {
  private http = inject(HttpService);
  private environment = inject(ENVIRONMENT);

  private baseUrl = `${this.environment.apiUrl}/api/linkjob`;

  getAsOfMonthAndYear(accountId: number) {
    const params = new HttpParams().set('accountId', accountId.toString());

    return this.http.get<
      BaseHttpResponseDto<string, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/asof/global`, params);
  }

  getPropertyList(id: string) {
    const params = new HttpParams().set('propertyId', id);

    return this.http.get<
      BaseHttpResponseDto<LinkAcrPropertyDto[], BaseHttpErrorDto[]>
    >(`${this.baseUrl}/acr/property/list`, params);
  }

  getPropertyReport(id: string, transactionIds: string[]) {
    const params = new HttpParams()
      .set('transactionIds', transactionIds.join(','))
      .set('propertyId', id);

    return this.http.get<
      BaseHttpResponseDto<LinkAcrReportResponseDto[], BaseHttpErrorDto[]>
    >(`${this.baseUrl}/acr/property/report`, params);
  }
}
