import { Component, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginUsernamePhaseComponent } from '../../components/login-username-phase/login-username-phase.component';
import { LoginPasswordPhaseComponent } from '../../components/login-password-phase/login-password-phase.component';
import { LoginForgotPasswordPhaseComponent } from '../../components/login-forgot-password-phase/login-forgot-password-phase.component';
import { LoginRecoveryCodePhaseComponent } from '../../components/login-recovery-code-phase/login-recovery-code-phase.component';
import { LoginResetPasswordPhaseComponent } from '../../components/login-reset-password-phase/login-reset-password-phase.component';
import { PrimeNgFramework } from '@velocity/common';
import { Phases } from '../../enums/login-phases';
import { EventEmitter } from '@angular/core';
import { PhaseChange } from '../../types/phase-change';
import { LoginFormValues } from '../../types/login-form-values';

@Component({
  selector: 'login-layout-default',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    LoginUsernamePhaseComponent,
    LoginPasswordPhaseComponent,
    LoginRecoveryCodePhaseComponent,
    LoginForgotPasswordPhaseComponent,
    LoginResetPasswordPhaseComponent
  ],
  providers: [
  ],
  templateUrl: './login-layout-default.component.html',
  styleUrls: ['./login-layout-default.component.scss']
})
export class LoginLayoutDefaultComponent {
  @Input() backgroundVideo!: string;
  @Input() logo!: string;
  @Input() currentPhase: Phases = Phases.Username;
  @Input() loading = false;
  @Input() formValues!: LoginFormValues;
  @Output() phaseChange = new EventEmitter<PhaseChange>();

  handlePhaseChange(event: PhaseChange) {
    this.phaseChange.emit(event);
  }

  phases = Phases;
}
