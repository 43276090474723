<login-layout
  [logo]="logo"
  [layout]="layout"
  [backgroundVideo]="backgroundVideo"
  [currentPhase]="store.currentPhase()"
  [loading]="store.loading()"
  [formValues]="store.formValues()"
  (phaseChange)="handlePhaseChange($event)"
>
</login-layout>
