import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  PrimeNgFramework,
  TypographyHeaderComponent,
  TypographyPComponent
} from '@velocity/common';
@Component({
  selector: 'password-criteria-text',
  standalone: true,
  imports: [
    PrimeNgFramework,
    CommonModule,
    TypographyHeaderComponent,
    TypographyPComponent
  ],
  templateUrl: './password-criteria-text.component.html',
  styleUrls: ['./password-criteria-text.component.scss']
})
export class PasswordCriteriaTextComponent {
  @Input() label = '';
  @Input() satisfied = false;
}
