<property-details-property-financials-layout
  [asOf]="store.propertyFinancials().asOf"
  [tableData]="store.propertyFinancials().tableData"
  [tableDataTotal]="store.propertyFinancials().tableDataTotal"
  [tableDataLoading]="store.propertyFinancials().tableDataLoading"
  [selectedProperties]="store.propertyFinancials().selectedProperties"
  [loading]="store.propertyFinancials().loading"
  [propertyList]="store.propertyFinancials().propertyList"
  [propertyListLoading]="store.propertyFinancials().propertyListLoading"
  (propertyListOptionSelect)="handleOptionUpdate($event)"
>
</property-details-property-financials-layout>
