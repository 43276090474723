import { InjectionToken } from "@angular/core";
import { UserIdleConfig } from 'angular-user-idle';
import { FirebaseOptions } from '@angular/fire/app';

export interface Environment {
  production: boolean;
  appName: string;
  appUrl: string;
  apiUrl: string;
  apimUrl: string;
  tokenName: string;
  configuration: {
    autologout: UserIdleConfig;
    firebase: FirebaseOptions;
  }
};

export const ENVIRONMENT = new InjectionToken<Environment>('ENVIRONMENT');
