<div class="container">
  <typography-header label="Set Password" [h]="4" [bold]="false"></typography-header>
  <typography-p
    label="Please set your new password. Your password must meet the following:"
    p="body2"
    class="text-color"
  >
  </typography-p>
  <div class="password-criteria-container">
    <password-criteria-text
      label="8 characters mininum"
      [satisfied]="resetPasswordForm.dirty 
        && !resetPasswordForm.hasError('minimum')"
    >
    </password-criteria-text>
    <password-criteria-text
      label="At least one special character (! , @ , # , $ , & , % , + , -)"
      [satisfied]="resetPasswordForm.dirty 
        && !resetPasswordForm.hasError('specialCharacter')"
    >
    </password-criteria-text>
    <password-criteria-text
      label="At least one uppercase letter"
      [satisfied]="resetPasswordForm.dirty 
        && !resetPasswordForm.hasError('capitalCase')"
    >
    </password-criteria-text>
    <password-criteria-text
      label="At least one lowercase letter"
      [satisfied]="resetPasswordForm.dirty 
        && !resetPasswordForm.hasError('smallCase')"
    >
    </password-criteria-text>
    <password-criteria-text
      label="At least one number"
      [satisfied]="resetPasswordForm.dirty 
        && !resetPasswordForm.hasError('numeric')"
    >
    </password-criteria-text>
  </div>
  <div class="input-container">
    <p-floatLabel>
      <p-password 
        [toggleMask]="true"
        [feedback]="false"
        inputStyleClass="full-width"
        styleClass="full-width"
        [formControl]="resetPasswordForm"
        name="password"
      />
      <label for="password">Password</label>
      <small
        class="p-error block"
        *ngIf="resetPasswordForm.hasError('required') && resetPasswordForm.dirty"
      >
        Password is required
      </small>
    </p-floatLabel>
  </div>
  <div class="input-container">
    <p-floatLabel>
      <p-password 
        [toggleMask]="true"
        [feedback]="false"
        inputStyleClass="full-width"
        styleClass="full-width"
        [formControl]="resetConfirmPasswordForm"
        name="confirm-password"
        required
      />
      <label for="confirm-password">Confirm Password</label>
      <small
        class="p-error block"
        *ngIf="resetConfirmPasswordForm.hasError('required') && resetConfirmPasswordForm.dirty"
      >
        Confirm password is required
      </small>
    </p-floatLabel>
  </div>
  <div class="input-container button-margin">
    <p-button
      label="Set password"
      [raised]="true"
      styleClass="full-width"
      [disabled]="!passwordConfirmed || resetPasswordForm.invalid"
      [loading]="loading"
      (onClick)="handleSetPassword()"
    >
    </p-button>
  </div>
</div>
