<p>Properties</p>
<custom-table
  [tableData]="store.properties().data"
  [columns]="columns"
  [hideColumnFilter]="true"
  (pageChangeEvent)="onPageChange($event)"
  (customSortEvent)="onCustomSort($event)"
  [scrollable]="true"
  [rows]="50"
  [customSort]="true"
  [totalRecords]="store.properties().totalItemCount"
  [loading]="store.properties().loading"
  scrollHeight="550px"
></custom-table>
