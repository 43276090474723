import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private http = inject(HttpClient);

  post<Response>(
    url: string,
    body?: unknown,
    params = new HttpParams(),
    responseType = 'json',
    customHeaders = {}
  ): Observable<Response> {
    const options = this.getOptions(params, customHeaders, responseType);
    if (body instanceof FormData) {
      options.headers = options.headers.delete('Content-Type');
    }

    return this.http.post<Response>(url, body, options);
  }

  put<Response>(
    url: string,
    body: unknown,
    params = new HttpParams(),
    responseType = 'json',
    customHeaders = {}
  ): Observable<Response> {
    const options = this.getOptions(params, customHeaders, responseType);
    return this.http.put<Response>(url, body, options);
  }

  get<Response>(
    url: string,
    params = new HttpParams(),
    responseType = 'json',
    customHeaders = {}
  ) {
    const options = this.getOptions(params, customHeaders, responseType);
    return this.http.get<Response>(url, options);
  }

  delete<Response>(
    url: string,
    params = new HttpParams(),
    responseType = 'json',
    customHeaders = {}
  ) {
    const options = this.getOptions(params, customHeaders, responseType);
    return this.http.delete<Response>(url, options);
  }

  private getOptions(
    params: HttpParams,
    headers?: HttpHeaders['headers'],
    responseType = 'json'
  ) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ...headers
      }),
      params,
      responseType: responseType as 'json' // workaround for HttpClient responseType
    };
  }
}
