import { inject, Injectable } from "@angular/core";
import { AuthApi, getBrowserName, UserProfileApi } from "@velocity/common";
import { map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PasswordRecoveryService {
  private userProfileApi = inject(UserProfileApi);
  private authApi = inject(AuthApi);

  requestPasswordReset(email: string) {
    const payload = {
      email,
      date: (new Date()).toISOString(),
      browser: getBrowserName(),
      approximateLocation: ''
    };

    return this.userProfileApi.sendResetPasswordCode(payload).pipe(
      map((value) => {
        if (value.errors) {
          throw { ...value.errors[0] };
        }

        return value;
      })
    );
  }

  resetPassword(email: string, password: string) {
    const payload = {
      username: email,
      password,
      confirmPassword: password
    };

    return this.authApi.resetPassword(payload).pipe(
      map((value) => {
        if (value.errors) {
          throw { ...value.errors[0] };
        }

        return value;
      })
    );
  }

  validateResetPasswordCode(email: string, code: string) {
    const payload = {
      isWelcome: true,
      emailAddress: email,
      key: code
    };

    return this.userProfileApi.validateResetPasswordCode(payload).pipe(
      map((value) => {
        if (value.errors) {
          throw { ...value.errors[0] };
        }

        return value;
      })
    );
  }
}
