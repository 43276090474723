import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CollapsibleCardComponent } from '../collapsible-card/collapsible-card.component';
import { KeyValueListComponent } from '../key-value-list/key-value-list.component';
import {
  PrimeNgFramework
} from '../../modules/primeng-framework';
import { KeyValueDetails } from '../../types';

@Component({
  selector: 'collapsible-information-card',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    CollapsibleCardComponent,
    KeyValueListComponent,
  ],
  providers: [
  ],
  templateUrl: './collapsible-information-card.component.html',
  styleUrls: ['./collapsible-information-card.component.scss']
  
})
export class CollapsibleInformationCardComponent {
  @Input() title!: string;
  @Input() icon = 'info';
  @Input() items: KeyValueDetails[] = [];
}
