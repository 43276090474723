<p-multiSelect
  styleClass="searchable-dropdown"
  [options]="items"
  [optionLabel]="optionLabel"
  [placeholder]="placeholder"
  [formControl]="selectedValues"
  (onChange)="handleOptionSelect($event)"
>
  <ng-template let-value pTemplate="selectedItems" *ngIf="selectedValuesAsChip">
    <div class="chip-container">
      <p-chip *ngFor="let item of value">
        <typography-p p="caption">
          {{item.label}}
        </typography-p>
      </p-chip>
    </div>
    <div *ngIf="!value || value.length === 0">{{placeholder}}</div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div>
      <typography-p p="caption" [bold]="!!item.sublabel">
        {{item.label}}
      </typography-p>
      <typography-p *ngIf="item.sublabel" p="caption">
        {{item.sublabel}}
      </typography-p>
    </div>
  </ng-template>
</p-multiSelect>
