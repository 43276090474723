@if (label) {
  <p
    [class]="'typography-p ' + p"
    [class.font-weight-bold]="bold"
    [align]="textAlign"
  >
    {{label}}
  </p>
} @else {
  <p
    [class]="'typography-p ' + p"
    [class.font-weight-bold]="bold"
    [align]="textAlign"
  >
    <ng-content></ng-content>
  </p>
}
