import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ComponentStore } from "@ngrx/component-store";
import {
  AppStateService,
  DropdownOption,
  formatAttributeKeys,
  GenericKeys,
  KeyValueDetails,
  LinkAcrPropertyDto,
  LinkAcrReportResponseDto,
  LinkJobApi,
  MediaCarouselItem,
  PropertyApi,
  PropertyDetailsDto,
  PropertyLandAcrApi,
  PropertyLandAcrDto,
  PropertyLandAcrReportResponseDto
} from "@velocity/common";
import { MessageService } from "primeng/api";
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  Observable,
  of,
  switchMap,
  tap,
  withLatestFrom
} from "rxjs";
import {
  propertyInfoKeys, // TODO: probably rename?
  ownershipInfoKeys,
  marketInfoKeys,
  PropertyDetailsTab,
  formatPropertyFinancialReportData,
  generatePropertyDetailsTab,
  formatSiteFinancialReportData
} from '@velocity/property';
import { isEqual } from 'lodash';

interface PropertyDetailsPageState {
  propertyAccountId: string;
  selectedPropertyFinancialFilter: DropdownOption[];
  selectedSiteFinancialFilter: DropdownOption[];
  details: {
    errorMessage?: string;
    error: boolean;
    loading: boolean;
    data?: PropertyDetailsDto;
  };
  asOf: {
    errorMessage?: string;
    error: boolean;
    loading: boolean;
    data?: string;
  };
  propertyListFilter: {
    errorMessage?: string;
    error: boolean;
    loading: boolean;
    data?: LinkAcrPropertyDto[];
  };
  propertyReport: {
    errorMessage?: string;
    error: boolean;
    loading: boolean;
    data?: LinkAcrReportResponseDto[];
  };
  siteFinancialListFilter: {
    errorMessage?: string;
    error: boolean;
    loading: boolean;
    data?: PropertyLandAcrDto[];
  };
  siteFinancialReport: {
    errorMessage?: string;
    error: boolean;
    loading: boolean;
    data?: PropertyLandAcrReportResponseDto[];
  }
}

const initialState: PropertyDetailsPageState = {
  selectedPropertyFinancialFilter: [],
  selectedSiteFinancialFilter: [],
  propertyAccountId: '',
  details: {
    error: false,
    loading: false
  },
  asOf: {
    error: false,
    loading: false
  },
  propertyListFilter: {
    error: false,
    loading: false
  },
  propertyReport: {
    error: false,
    loading: false
  },
  siteFinancialListFilter: {
    error: false,
    loading: false
  },
  siteFinancialReport: {
    error: false,
    loading: false
  }
};

@Injectable({
  providedIn: 'root'
})
export class PropertyDetailsStore extends ComponentStore<PropertyDetailsPageState> {
  private appState = inject(AppStateService);
  private propertyApi = inject(PropertyApi);
  private linkJobApi = inject(LinkJobApi);
  private propertyLandAcrApi = inject(PropertyLandAcrApi);
  private messageService = inject(MessageService);
  private router = inject(Router);

  constructor() {
    super(initialState);
  }

  readonly clearPreviousCalls = this.updater((state) => ({
    ...state,
    asOf: {
      errorMessage: undefined,
      error: false,
      loading: false,
      data: undefined
    },
    propertyListFilter: {
      errorMessage: undefined,
      error: false,
      loading: false,
      data: undefined
    },
    propertyReport: {
      errorMessage: undefined,
      error: false,
      loading: false,
      data: undefined
    },
    siteFinancialReport: {
      errorMessage: undefined,
      error: false,
      loading: false,
      data: undefined
    },
    siteFinancialListFilter: {
      errorMessage: undefined,
      error: false,
      loading: false,
      data: undefined
    },
    details: {
      errorMessage: undefined,
      error: false,
      loading: false,
      data: undefined
    },
  }));


  readonly setSiteFinancialListFilterFetching = this.updater((state, loading: boolean) => ({
    ...state,
    siteFinancialListFilter: {
      ...state.siteFinancialListFilter,
      loading
    }
  }));

  readonly setSiteFinancialReportFetching = this.updater((state, loading: boolean) => ({
    ...state,
    siteFinancialReport: {
      ...state.siteFinancialReport,
      loading
    }
  }));

  readonly setPropertyListFilterFetching = this.updater((state, loading: boolean) => ({
    ...state,
    propertyListFilter: {
      ...state.propertyListFilter,
      loading
    }
  }));

  readonly setPropertyFinancialReportFetching = this.updater((state, loading: boolean) => ({
    ...state,
    propertyReport: {
      ...state.propertyReport,
      loading
    }
  }));

  readonly setAsOfFetching = this.updater((state, loading: boolean) => ({
    ...state,
    asOf: {
      ...state.asOf,
      loading
    }
  }));

  readonly setDetailsFetching = this.updater((state, loading: boolean) => ({
    ...state,
    details: {
      ...state.details,
      loading
    }
  }));

  readonly updateSelectedPropertyFilter = this.updater((state, filter: DropdownOption[]) => ({
    ...state,
    selectedPropertyFinancialFilter: filter,
    propertyReport: {
      ...state.propertyReport,
      data: undefined
    }
  }));

  readonly updateSelectedSiteFinancialFilter = this.updater((state, filter: DropdownOption[]) => ({
    ...state,
    selectedSiteFinancialFilter: filter,
    siteFinancialReport: {
      ...state.siteFinancialReport,
      data: undefined
    }
  }));

  readonly resolveAsOf = this.updater((state, data: string) => ({
    ...state,
    asOf: {
      error: false,
      errorMessage: undefined,
      loading: false,
      data
    }
  }));

  readonly resolvePropertyFinancialReport = this.updater((state, data: LinkAcrReportResponseDto[]) => ({
    ...state,
    propertyReport: {
      error: false,
      errorMessage: undefined,
      loading: false,
      data
    },
  }));

  readonly resolveSiteFinancialReport = this.updater((state, data: PropertyLandAcrReportResponseDto[]) => ({
    ...state,
    siteFinancialReport: {
      error: false,
      errorMessage: undefined,
      loading: false,
      data
    },
  }));

  readonly resolvePropertyListFilter = this.updater((state, data: LinkAcrPropertyDto[]) => ({
    ...state,
    propertyListFilter: {
      error: false,
      errorMessage: undefined,
      loading: false,
      data
    },
    selectedPropertyFinancialFilter: [
      /** select all by default */
      ...data.map((item): DropdownOption => ({
        id: item.id.toString(),
        label: item.name,
        value: item.transactionId
      }))
    ] 
  }));

  readonly resolveSiteFinancialListFilter = this.updater((state, data: PropertyLandAcrDto[]) => ({
    ...state,
    siteFinancialListFilter: {
      error: false,
      errorMessage: undefined,
      loading: false,
      data
    },
    selectedSiteFinancialFilter: [
      /** select all by default */
      ...data.map((item): DropdownOption => ({
        id: item.id.toString(),
        label: item.name,
        sublabel: `${item.propertyAccountId} (${item.userJobId} ${item.jobTypeShort})`,
        value: item.transactionId
      }))
    ] 
  }));

  readonly setPropertyAccountId = this.updater((state, propertyAccountId: string) => ({
    ...state,
    propertyAccountId
  }));

  readonly resolveDetails = this.updater((state, data: PropertyDetailsDto) => ({
    ...state,
    details: {
      error: false,
      errorMessage: undefined,
      loading: false,
      data
    }
  }));

  readonly loadInitialDetails = this.effect(() => {
    return from(this.select((state) => state.propertyAccountId)).pipe(
      withLatestFrom(
        this.appState.accountId$
      ),
      filter(([propertyAccountId, accountId]) => !!propertyAccountId && !!accountId),
      tap(() => {
        this.clearPreviousCalls();
        this.setDetailsFetching(true);
        this.appState.setHeaderDetailsLoading(true);
      }),
      switchMap(([propertyAccountId, accountId]) => this.propertyApi.getPropertyDetails(
        propertyAccountId,
        accountId as number
      ).pipe(
        withLatestFrom(this.appState.permissions$),
        tap(([value, permissions]) => {
          const response = value.data;
          this.resolveDetails(response);
          this.appState.setHeaderDetails({
            pageIdentifier: 'property-details',
            visible: true,
            loading: false,
            title: response.propertyName,
            subtitle: response.propertyAccountId,
            breadcrumbs: [
              {
                label: 'Properties',
                url: '/properties'
              },
              {
                label: response.propertyName,
                url: `/properties/details/${response.propertyAccountId}`
              }
            ],
            attributes: [
              {
                key: 'Status',
                value: response.locationStatus,
                type: 'text'
              },
              {
                key: 'Address',
                value: response.addressLine1,
                type: 'text'
              },
            ],
            tabs: generatePropertyDetailsTab(permissions)
          });
        })
      )),
      catchError((error: { response: string[] }, caught: Observable<unknown>) => {
        this.appState.setHeaderDetails({
          pageIdentifier: 'property-details',
          visible: false,
          loading: false,
          breadcrumbs: [],
          attributes: [],
          tabs: []
        });

        this.messageService.add({
          severity: 'error',
          detail: error.response?.[0] ?? 'Could not find property'
        });

        this.router.navigateByUrl('/');

        return of(caught);
      })
    )
  });

  readonly loadAsOf = this.effect(() => {
    return combineLatest([
      this.appState.currentPageTab$,
      this.select((state) => state.asOf.data),
      this.appState.accountId$,
      this.select((state) => state.propertyAccountId),
    ]).pipe(
      filter(([tab]) => 
        [
          PropertyDetailsTab.SITE_FINANCIALS,
          PropertyDetailsTab.PROPERTY_FINANCIALS
        ].includes(tab)
      ),
      distinctUntilChanged(([,,,prevPropertyAccountId], [,,,propertyAccountId]) => 
        prevPropertyAccountId === propertyAccountId
      ),
      tap(() => {
        this.setAsOfFetching(true);
      }),
      switchMap(([,, accountId]) => this.linkJobApi.getAsOfMonthAndYear(
        accountId as number
      ).pipe(
        tap((value) => {
          this.resolveAsOf(value.data);
        })
      ))
    );
  });

  readonly loadPropertyFinancialListFilter = this.effect(() => {
    return combineLatest([
      this.appState.currentPageTab$,
      this.select((state) => state.propertyListFilter.data),
      this.select((state) => state.details.data?.id),
      this.select((state) => state.propertyAccountId),
    ]).pipe(
      filter(([tab,, id]) => 
        tab === PropertyDetailsTab.PROPERTY_FINANCIALS
        && !!id
      ),
      distinctUntilChanged(([,,,prevPropertyAccountId], [,,,propertyAccountId]) => 
        prevPropertyAccountId === propertyAccountId
      ),
      tap(() => {
        this.setPropertyListFilterFetching(true);
      }),
      switchMap(([,, id]) => this.linkJobApi.getPropertyList(
        id?.toString() as string
      ).pipe(
        tap((value) => {
          this.resolvePropertyListFilter(value.data);
        })
      ))
    );
  });

  readonly loadSiteFinancialListFilter = this.effect(() => {
    return combineLatest([
      this.appState.currentPageTab$,
      this.select((state) => state.propertyListFilter.data),
      this.select((state) => state.details.data?.id),
      this.select((state) => state.propertyAccountId),
    ]).pipe(
      filter(([tab,, id]) => 
        tab === PropertyDetailsTab.SITE_FINANCIALS
        && !!id
      ),
      distinctUntilChanged(([,,,prevPropertyAccountId], [,,,propertyAccountId]) => 
        prevPropertyAccountId === propertyAccountId
      ),
      tap(() => {
        this.setSiteFinancialListFilterFetching(true);
      }),
      switchMap(([,, id]) => this.propertyLandAcrApi.getPropertyListAcrReporting(
        id as number
      ).pipe(
        tap((value) => {
          this.resolveSiteFinancialListFilter(value.data);
        })
      ))
    );
  });

  readonly loadPropertyFinancialReport = this.effect(() => {
    return combineLatest([
      this.appState.currentPageTab$,
      this.select((state) => state.propertyListFilter.data),
      this.select((state) => state.details.data?.id),
      this.select((state) => state.propertyReport.data),
      this.select((state) => state.selectedPropertyFinancialFilter)
    ]).pipe(
      distinctUntilChanged(([,,,,previousFilter], [,,,,currentFilter]) => 
        isEqual(previousFilter, currentFilter)
      ),
      filter(([tab, propertyListFilter, id, reportData]) => 
        tab === PropertyDetailsTab.PROPERTY_FINANCIALS
        && !!id
        && !!propertyListFilter
        && !reportData
      ),
      tap(() => {
        this.setPropertyFinancialReportFetching(true);
      }),
      switchMap(([,,propertyId ,, selected]) => this.linkJobApi.getPropertyReport(
        propertyId?.toString() as string,
        selected.map((item) => item.value.toString()) 
      ).pipe(
        tap((value) => {
          this.resolvePropertyFinancialReport(value.data);
        })
      ))
    )
  });


  readonly loadSiteFinancialReport = this.effect(() => {
    return combineLatest([
      this.appState.currentPageTab$,
      this.select((state) => state.siteFinancialListFilter.data),
      this.select((state) => state.details.data?.id),
      this.select((state) => state.propertyReport.data),
      this.select((state) => state.selectedSiteFinancialFilter)
    ]).pipe(
      distinctUntilChanged(([,,,,previousFilter], [,,,,currentFilter]) => 
        isEqual(previousFilter, currentFilter)
      ),
      tap((d) => {
        console.log(d);
      }),
      filter(([tab, listFilter, id, reportData]) => 
        tab === PropertyDetailsTab.SITE_FINANCIALS
        && !!id
        && !!listFilter
        && !reportData
      ),
      tap(() => {
        console.log('test');
        this.setSiteFinancialReportFetching(true);
      }),
      switchMap(([,,propertyId ,, selected]) => this.propertyLandAcrApi.getPropertyAcrReporting(
        propertyId as number,
        selected.map((item) => item.value.toString()).join(',')
      ).pipe(
        tap((value) => {
          this.resolveSiteFinancialReport(value.data);
        })
      ))
    )
  });

  get propertyAccountId() {
    return this.selectSignal((state) => state.propertyAccountId)();
  }

  get overview() {
    return this.selectSignal((state) => {
      const mergedData = {
        ...state.details.data,
        ...(state.details.data?.propertyDetailDto as PropertyDetailsDto)
      };

      const media = state.details.data?.images.map((image): MediaCarouselItem => ({
        id: image.id,
        size: image.size,
        date: image.modifiedDate,
        name: image.serverFileName,
        image: image.absoluteUri
      })) ?? [];
  
      return {
        loading: state.details.loading,
        propertyInfo: formatAttributeKeys(propertyInfoKeys as GenericKeys, mergedData) as KeyValueDetails[],
        ownershipInfo: formatAttributeKeys(ownershipInfoKeys, mergedData) as KeyValueDetails[],
        marketInfo: formatAttributeKeys(marketInfoKeys, mergedData) as KeyValueDetails[],
        assignedUsers: mergedData.storeAssignmentsDto ?? [],
        media: media.length > 0 ? media : [
          {
            id: 0,
            size: 0,
            date: '',
            name: 'no-image-found',
            image: 'assets/icons/no-image.jpg'
          }
        ]
      };
    });
  }

  get propertyFinancials() {
    return this.selectSignal((state) => {
      const reportData = state.propertyReport.data 
        ? formatPropertyFinancialReportData(state.propertyReport.data as LinkAcrReportResponseDto[])
        : { total: [], value: [] };
      return {
        asOf: state.asOf.data ?? '',
        tableData: reportData.value,
        tableDataTotal: reportData.total,
        tableDataLoading: state.propertyReport.loading,
        propertyList: state.propertyListFilter.data?.map((item): DropdownOption => ({
          id: item.id.toString(),
          label: item.name,
          value: item.transactionId
        })) ?? [],
        propertyListLoading: state.propertyListFilter.loading,
        selectedProperties: state.selectedPropertyFinancialFilter,
        loading: state.propertyReport.loading &&
           (state.propertyListFilter.loading
          || state.details.loading
          || state.asOf.loading)
      }
    });
  }


  get siteFinancials() {
    return this.selectSignal((state) => {
      const reportData = state.siteFinancialReport.data 
        ? formatSiteFinancialReportData(state.siteFinancialReport.data as PropertyLandAcrReportResponseDto[])
        : { total: [], value: [] };
      return {
        asOf: state.asOf.data ?? '',
        tableData: reportData.value,
        tableDataTotal: reportData.total,
        tableDataLoading: state.siteFinancialReport.loading,
        propertyList: state.siteFinancialListFilter.data?.map((item): DropdownOption => ({
          id: item.id.toString(),
          label: item.name,
          sublabel: `${item.propertyAccountId} (${item.userJobId} ${item.jobTypeShort})`,
          value: item.transactionId
        })) ?? [],
        propertyListLoading: state.siteFinancialListFilter.loading,
        selectedProperties: state.selectedSiteFinancialFilter,
        loading: state.siteFinancialReport.loading &&
           (state.siteFinancialListFilter.loading
          || state.details.loading
          || state.asOf.loading)
      }
    });
  }

  get activeTab() {
    return this.appState.pageDetails().activeTab;
  }
}
