import { Injectable, inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppStateService } from '@velocity/common';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private appStateService = inject(AppStateService);

  async canActivate() {
    this.appStateService.loadUser();

    return true;
  }
}
