import { AppState } from "../../types/app-state";

export const initialState: AppState = {
  previousUrl: null,
  forwardToPage: null,
  user: {
    accessToken: {
      hasError: false,
      error: null,
      loading: false,
      value: ''
    },
    details: {
      hasError: false,
      error: null,
      loading: false,
      value: {}
    },
    permissions: {
      hasError: false,
      error: null,
      loading: false,
      value: []
    }
  },
  header: {
    pageLoading: false,
    searching: false,
    searchResult: [],
    menu: [],
    links: [],
    details: {
      pageIdentifier: 'undefined',
      visible: false,
      loading: false,
      breadcrumbs: [],
      rightActionButtons: [],
      attributes: [],
      tabs: []
    }
  }
};
