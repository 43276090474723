<div class="property-details-overview-container" *ngIf="!loading">
  <div class="property-details-overview-sub-container">
    <property-gallery [media]="media">
    </property-gallery>
    <ng-template [ngxPermissionsOnly]="permissions.PROPERTY_OVERVIEW_PROPERTY_INFO_VIEW">
      <collapsible-information-card
        icon="storefront"
        title="PROPERTY INFORMATION"
        [items]="propertyInfo"
      >
      </collapsible-information-card>
    </ng-template>
    <ng-template [ngxPermissionsOnly]="permissions.PROPERTY_OVERVIEW_OWNERSHIP_INFO_VIEW">
      <collapsible-information-card
        icon="storefront"
        title="OWNERSHIP INFORMATION"
        [items]="ownershipInfo"
      >
      </collapsible-information-card>
    </ng-template>
    <ng-template [ngxPermissionsOnly]="permissions.PROPERTY_OVERVIEW_MARKET_INFO_VIEW">
      <collapsible-information-card
        icon="storefront"
        title="MARKET INFORMATION"
        [items]="marketInfo"
      >
      </collapsible-information-card>
    </ng-template>
  </div>
  <div class="property-details-overview-sub-container">
    <ng-template [ngxPermissionsOnly]="permissions.PROPERTY_OVERVIEW_MARKET_INFO_VIEW">
      <collapsible-card
        icon="comment"
        title="NOTES"
      >
        <ng-container slot="content">
          <div class="content-container">
            Notes coming soon
          </div>
        </ng-container>
      </collapsible-card>
    </ng-template>
    <ng-template [ngxPermissionsOnly]="permissions.PROPERTY_OVERVIEW_ASSIGNMENTS_VIEW">
      <collapsible-card
        icon="assignment_ind"
        title="ASSIGNMENTS"
      >
        <ng-container slot="content">
          <property-assignment-user-role-card-list
            [assignedUsers]="assignedUsers"
          >
          </property-assignment-user-role-card-list>
        </ng-container>
      </collapsible-card>
    </ng-template>
  </div>
</div>

<div class="property-details-overview-container" *ngIf="loading">
  <div class="property-details-overview-sub-container">
    <p-skeleton width="100%" height="50ch"></p-skeleton>
  </div>
  <div class="property-details-overview-sub-container">
    <p-skeleton width="100%" height="10ch"></p-skeleton>
    <p-skeleton width="100%" height="20ch"></p-skeleton>
  </div>
</div>
