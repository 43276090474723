@if (label) {
  <p
    [class]="'typography-header h' + h"
    [class.font-weight-normal]="!bold"
    [align]="textAlign"
  >
    {{label}}
  </p>
} @else {
  <p
    [class]="'typography-header h' + h"
    [class.font-weight-normal]="!bold"
    [align]="textAlign"
  >
    <ng-content></ng-content>
  </p>
}
