import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent, PrimeNgFramework, TypographyPComponent } from '@velocity/common';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    PrimeNgFramework,
    TypographyPComponent,
    FooterComponent
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {}
