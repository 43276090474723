<p-toolbar styleClass="navigation-toolbar">
  <ng-template pTemplate="start">
    <dashboard-navigation-logo
      [logo]="logo"
      [logoShort]="logoShort"
    >
    </dashboard-navigation-logo>
    <p-divider
      layout="vertical"
      styleClass="navigation-divider"
      *ngIf="links.length"
    />
    <p-menubar
      [model]="links"
      styleClass="navigation-menubar"
      *ngIf="links.length"
    >
    </p-menubar>
  </ng-template>
  <ng-template pTemplate="center">
    <dashboard-navigation-search
      [results]="searchResult"
      (search)="handleSearch($event)"
      (selectResult)="handleSelectSearchResult($event)"
    >
    </dashboard-navigation-search>
  </ng-template>
  <ng-template pTemplate="end">
    <p-button [rounded]="true" [text]="true" *ngIf="false"> <!--temporary hide -->
      <ng-template pTemplate="icon">
        <span class="material-icons navigation-icon">settings</span>
      </ng-template>
    </p-button>

    <dashboard-navigation-menu
      [menu]="menu"
      *ngIf="menu.length"
    >
    </dashboard-navigation-menu>

    <dashboard-navigation-account
      *ngIf="selectedAccount"
      [accounts]="user.accounts"
      [selectedAccount]="selectedAccount"
      (accountChange)="handleAccountChange($event)"
    >
    </dashboard-navigation-account>
    <dashboard-navigation-profile
      [user]="user"
      (logout)="handleLogout()"
    >
    </dashboard-navigation-profile>
  </ng-template>
</p-toolbar>
<p-progressBar
  mode="indeterminate"
  styleClass="navigation-progressbar"
  *ngIf="loading"
/>
