<p-galleria
  [value]="media"
  [numVisible]="visibleThumbnails"
  [showItemNavigators]="true"
  containerClass="media-carousel"
  [activeIndex]="activeIndex"
>
  <ng-template pTemplate="item" let-item>
    <img
      [src]="item.image"
      class="media-carousel-display"
      [class.limit-width]="limitWidth"
      alt=""
    />
  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div class="grid grid-nogutter justify-content-center">
      <img
        [id]="item.id"
        [src]="item.image"
        class="media-carousel-thumbnail"
        alt=""
      />
    </div>
  </ng-template>
</p-galleria>
