import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import {
  PrimeNgFramework,
  TypographyPComponent,
  TypographyHeaderComponent,
  RightActionButton,
  DerivedHeader
} from "@velocity/common";
import { DashboardBreadcrumbsComponent } from "../breadcrumbs/breadcrumbs.component";
import { DashboardDetailsAreaTitleComponent } from "../details-area-title/details-area-title.component";
import { DashboardDetailsAreaAttributesComponent } from "../details-area-attributes/details-area-attributes.component";
import { RightActionButtonPayload } from "@velocity/common";
import { DashboardDetailsAreaTabMenuComponent } from "../details-area-tab-menu/details-area-tab-menu.component";

@Component({
  selector: 'dashboard-details-area',
  styleUrl: './details-area.component.scss',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyHeaderComponent,
    TypographyPComponent,
    DashboardBreadcrumbsComponent,
    DashboardDetailsAreaTitleComponent,
    DashboardDetailsAreaAttributesComponent,
    DashboardDetailsAreaTabMenuComponent
],
  templateUrl: './details-area.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DashboardDetailsAreaComponent {
  @Input() header!: DerivedHeader;
  @Output() rightActionButton = new EventEmitter<Omit<RightActionButtonPayload, 'pageIdentifier'>>();

  handleActionButton(action: RightActionButton) {
    this.rightActionButton.emit({
      ...action.payload
    });
  }

  getInitials(name: string) {
    const initials = name.split(' ').map((value) => value.charAt(0));

    return `${initials[0]}${initials[initials.length -1]}`;
  }

  castArray(value: string | string[]) {
    return value as string[];
  }
}
