import { BrowserType } from "../types";

export const getBrowserName = (): BrowserType => {
  const agent = window.navigator.userAgent.toLocaleLowerCase();

  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'Microsoft Edge';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    case agent.indexOf('opr') > -1 && !!(window as any).opr:
      return 'Opera';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
      return 'Google Chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'Mozilla Firefox';
    case agent.indexOf('safari') > -1:
      return 'Safari';
    default:
      return 'Unknown Browser';
  }
};
