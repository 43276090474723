import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, Validators, FormControl } from '@angular/forms';
import { PhaseChange } from '../../types/phase-change';
import { Phases } from '../../enums/login-phases';
import { PrimeNgFramework } from '@velocity/common';

@Component({
  selector: 'login-username-phase',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './login-username-phase.component.html',
  styleUrls: ['./login-username-phase.component.scss']
})
export class LoginUsernamePhaseComponent implements OnChanges {
  @Input() username = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Output() phaseChange = new EventEmitter<PhaseChange>();

  usernameForm = new FormControl<string>('');
  
  constructor() {
    this.usernameForm.setValue(this.username);
    this.usernameForm.setValidators([Validators.required, Validators.email]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['username']?.currentValue) {
      this.usernameForm.setValue(this.username);
      this.usernameForm.setValidators([Validators.required, Validators.email]);
    }

    if (changes['loading']) {
      const isLoading = changes['loading']?.currentValue;
      this.usernameForm[isLoading ? 'disable' : 'enable']();
    }
  }

  handleNextPhase() {
    this.usernameForm.markAllAsTouched();
    this.usernameForm.markAsDirty();

    if (this.usernameForm.invalid) {
      return;
    }

    this.phaseChange.emit({
      formData: {
        username: this.usernameForm?.value
      },
      next: Phases.Password
    });
  }
}
