import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormControl, Validators } from '@angular/forms';
import {
  PrimeNgFramework,
  TypographyHeaderComponent,
  TypographyPComponent,
  LoginValidators
} from '@velocity/common';
import { PhaseChange } from '../../types/phase-change';
import { PasswordCriteriaTextComponent } from '../password-criteria-text/password-criteria-text.component';
import { Phases } from '../../enums/login-phases';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'login-reset-password-phase',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TypographyHeaderComponent,
    TypographyPComponent,
    PasswordCriteriaTextComponent
  ],
  templateUrl: './login-reset-password-phase.component.html',
  styleUrls: ['./login-reset-password-phase.component.scss']
})
export class LoginResetPasswordPhaseComponent implements OnDestroy {
  @Input() loading = false;
  @Output() phaseChange = new EventEmitter<PhaseChange>();

  resetPasswordForm = new FormControl<string>('');
  resetConfirmPasswordForm = new FormControl<string>('');

  matcher$;
  passwordConfirmed = false;

  constructor() {
    this.resetPasswordForm.setValue('');
    this.resetConfirmPasswordForm.setValue('');
    this.resetPasswordForm.setValidators([
      Validators.required,
      LoginValidators.requireLowerCase,
      LoginValidators.requireUpperCase,
      LoginValidators.requireSpecialCharacter,
      LoginValidators.requireNumeric,
      LoginValidators.require8AsMinLength
    ]);
    this.resetConfirmPasswordForm.setValidators([
      Validators.required
    ]);

    this.matcher$ = combineLatest([
      this.resetPasswordForm.valueChanges,
      this.resetConfirmPasswordForm.valueChanges
    ]).subscribe(([value1, value2]) => {
      this.passwordConfirmed = value1 === value2;
    });
  }

  ngOnDestroy() {
    this.matcher$.unsubscribe();
  }

  handleSetPassword() {
    this.phaseChange.emit({
      formData: {
        newPassword: this.resetPasswordForm.value
      },
      next: Phases.Password
    });
  }
}
