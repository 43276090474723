<div #profileMenuAnchor>
  <p-menu
    #profileMenu
    [popup]="true"
    [appendTo]="profileMenuAnchor"
    (onFocus)="handleTest($event)"
    styleClass="navigation-profile-menu"
  >
    <ng-template pTemplate="start">
      <div class="menu-avatar">
        <span>
          <p-avatar
            *ngIf="user.avatar?.length"
            [image]="user.avatar"
            styleClass="avatar-border"
            shape="circle"
            size="large"
          />
          <p-avatar
            *ngIf="!user.avatar?.length"
            shape="circle"
            styleClass="avatar-border"
            [label]="label"
            size="large"
          />
        </span>
        <div class="menu-user-info">
          <typography-p
            [label]="user.fullName"
            p="body2"
            [bold]="true"
          >
          </typography-p>
          <typography-p
            [label]="user.account?.role ?? 'Not authenticated'"
            p="subtitle2"
          >
          </typography-p>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="item"></ng-template>
    <ng-template pTemplate="end">
      <div class="menu-end-container">
        <div class="menu-end">
          <button pButton [text]="true" class="menu-item" (click)="handleLogout()">
            <span class="pi pi-sign-out menu-item-text"></span>
            <span class="menu-item-text">Logout</span>
          </button>
        </div>
      </div>
    </ng-template>
  </p-menu>
  <span class="clickable-avatar" (click)="profileMenu.toggle($event)">
    <p-avatar
      *ngIf="user.avatar?.length"
      [image]="user.avatar"
      shape="circle"
      styleClass="avatar-border"
    />
    <p-avatar
      *ngIf="!user.avatar?.length"
      shape="circle"
      styleClass="avatar-border"
      [label]="label"
    />
  </span>
</div>