import { Component, Input } from '@angular/core';
import { PrimeNgFramework } from '../../modules/primeng-framework';
import { TypographyPComponent } from '../typography-p/typography-p.component';
import { KeyValueDetails } from '../../types';

@Component({
  selector: 'key-value-list',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent
  ],
  styleUrl: './key-value-list.component.scss',
  templateUrl: './key-value-list.component.html'
})
export class KeyValueListComponent {
  @Input() items: KeyValueDetails[] = [];
}
