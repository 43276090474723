<footer class="common-footer">
  <typography-p
    p="caption"
    class="text"
    [label]="footerText"
  >
  </typography-p>
  <a
    [href]="'mailto:' + supportEmail"
    pButton
    class="footer-button"
    [text]="true"
    [plain]="true"
  >
    Support
  </a>
</footer>
