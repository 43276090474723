import { Component, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PrimeNgFramework } from '@velocity/common';
import { Phases } from '../enums/login-phases';
import { EventEmitter } from '@angular/core';
import { PhaseChange } from '../types/phase-change';
import { LoginFormValues } from '../types/login-form-values';
import { LoginLayoutDefaultComponent } from "./login-layout-default/login-layout-default.component";
import { LoginLayoutHolidayComponent } from './login-layout-holiday/login-layout-holiday.component';

@Component({
  selector: 'login-layout',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    LoginLayoutDefaultComponent,
    LoginLayoutHolidayComponent
],
  providers: [],
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent {
  @Input() layout: 'default' | 'holiday' = 'default';
  @Input() backgroundVideo!: string;
  @Input() logo!: string;
  @Input() currentPhase: Phases = Phases.Username;
  @Input() loading = false;
  @Input() formValues!: LoginFormValues;
  @Output() phaseChange = new EventEmitter<PhaseChange>();

  handlePhaseChange(event: PhaseChange) {
    this.phaseChange.emit(event);
  }

  phases = Phases;
}
