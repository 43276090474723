import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PrimeNgFramework, TypographyPComponent, UserInfo } from '@velocity/common';


@Component({
  selector: 'dashboard-navigation-profile',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgFramework,
    TypographyPComponent
  ],
  templateUrl: './navigation-profile.component.html',
  styleUrls: ['./navigation-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavigationProfileComponent {
  @Input() user!: UserInfo;
  @Output() logout = new EventEmitter<boolean>();

  handleLogout() {
    this.logout.emit(true);
  }

  handleTest(e: unknown) {
    console.log(e);
  }

  get label() {
    return `${this.user.firstName.charAt(0)}${this.user.lastName.charAt(0)}`;
  }
}
