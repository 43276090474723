import { inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../provider-tokens";
import { HttpService } from "../http.service";
import { HttpParams } from "@angular/common/http";
import {
  UserProfileResetPasswordPayloadDto,
  UserProfileValidateCodePayloadDto,
  BaseHttpErrorDto,
  BaseHttpResponseDto,
  UserProfileDto
} from "../../types";

@Injectable({
  providedIn: 'root'
})
export class UserProfileApi {
  private http = inject(HttpService);
  private environment = inject(ENVIRONMENT);

  private baseUrl = `${this.environment.apiUrl}/api/userprofile`;

  getUserProfileToken() {
    return this.http.get<
      BaseHttpResponseDto<string, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/token`);
  }

  sendResetPasswordCode(payload: UserProfileResetPasswordPayloadDto) {
    return this.http.post<
      BaseHttpResponseDto<unknown, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/email`, payload);
  }

  getUserDetails(userId: string, accountId: string) {
    const params = new HttpParams({
      fromObject: {
        id: userId,
        accountId
      }
    });

    return this.http.get<
      BaseHttpResponseDto<UserProfileDto, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/detail`, params);
  }

  validateResetPasswordCode(payload: UserProfileValidateCodePayloadDto) {
    return this.http.post<BaseHttpResponseDto<unknown, BaseHttpErrorDto[]>>(
      `${this.baseUrl}/code/password`,
      payload
    );
  }

  updateUserProfile(payload: UserProfileDto) {
    return this.http.put<BaseHttpResponseDto<unknown, BaseHttpErrorDto[]>>(
      `${this.baseUrl}/profile`,
      payload
    );
  }
}
