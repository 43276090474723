import { makeEnvironmentProviders, isDevMode } from "@angular/core";
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { routerReducer, provideRouterStore } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { appStateFeature } from "./app-state.feature";
import { provideEffects } from "@ngrx/effects";
import { AppStateEffects } from "./app-state.effects";
import { AppStateService } from "./app-state.service";
import { Environment } from "../../provider-tokens";
import { provideUserIdleConfig } from 'angular-user-idle';
import { MessageService } from "primeng/api";
import { LegacyPermissions, PermissionHandler } from "../permission";
import { DialogService } from "primeng/dynamicdialog";

export function provideAppState(environment: Environment) {
  return makeEnvironmentProviders([
    {
      provide: PermissionHandler,
      useClass: LegacyPermissions
    },

    AppStateService,
    MessageService,
    DialogService,

    provideStore({
      router: routerReducer
    }),
    provideRouterStore(),
    provideState(appStateFeature),
    // TODO: should we make this configurable?
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
      connectInZone: true
    }),
    provideUserIdleConfig(environment.configuration.autologout),
    provideEffects(AppStateEffects)
  ]);
}
