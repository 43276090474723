import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { PropertyApi } from '@velocity/common';
import { catchError, combineLatest, of, switchMap, tap } from 'rxjs';
import { PropertiesResponseDto, PropertyItem } from '@velocity/common';

interface PropertiesFilter {
  accountId: number;
  page: number;
  pageSize: number;
  assetStatus?: string | null;
  cities?: string | null;
  licenseInPlace?: boolean | null;
  linkUser?: string | null;
  market?: string | null;
  propertyReportingType?: string | null;
  propertyType?: string | null;
  region?: string | null;
  repm?: string[];
  searchString?: string;
  sortKey: string[];
  sortOrder: ('asc' | 'desc')[];
  state?: string | null;
  status?: string | null;
  subleaseInPlace?: boolean | null;
}

interface PropertiesPageState {
  list: {
    errorMessage?: string;
    error: boolean;
    loading: boolean;
    data?: PropertyItem[];
    firstItemOnPage: number;
    lastItemOnPage: number;
    totalItemCount: number;
  };
  filter: PropertiesFilter;
}

const initialState: PropertiesPageState = {
  list: {
    error: false,
    loading: false,
    data: [],
    firstItemOnPage: 1,
    lastItemOnPage: 1,
    totalItemCount: 1,
  },
  filter: {
    accountId: 4,
    page: 1,
    pageSize: 50,
    assetStatus: null,
    cities: null,
    licenseInPlace: null,
    linkUser: null,
    market: null,
    propertyReportingType: null,
    propertyType: null,
    region: null,
    repm: [],
    searchString: '',
    sortKey: ['propertyAccountId'],
    sortOrder: ['asc'],
    state: null,
    status: null,
    subleaseInPlace: null,
  },
};

@Injectable({
  providedIn: 'root',
})
export class PropertiesStore extends ComponentStore<PropertiesPageState> {
  private propertyApi = inject(PropertyApi);

  constructor() {
    super(initialState);
  }

  readonly clearPreviousCalls = this.updater((state) => ({
    ...state,
    list: {
      ...state.list,
      errorMessage: undefined,
      error: false,
      loading: false,
    },
  }));

  readonly setPropertiesFetching = this.updater((state, loading: boolean) => ({
    ...state,
    list: {
      ...state.list,
      loading,
    },
  }));

  readonly resolveProperties = this.updater(
    (state, data: PropertiesResponseDto) => ({
      ...state,
      list: {
        error: false,
        loading: false,
        data: data.items,
        firstItemOnPage: data.firstItemOnPage,
        lastItemOnPage: data.lastItemOnPage,
        totalItemCount: data.totalItemCount,
      },
    })
  );

  readonly setPropertiesOnCatchError = this.updater(
    (state, message: string) => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
        error: true,
        errorMessage: message,
      },
    })
  );

  readonly loadPropertiesList = this.effect(() => {
    return combineLatest([this.select((state) => state.filter)]).pipe(
      tap(() => {
        this.clearPreviousCalls();
        this.setPropertiesFetching(true);
      }),

      switchMap(([filter]) =>
        this.propertyApi.getPropertiesList(filter).pipe(
          tap((response: any) => {
            this.resolveProperties(response);
          }),
          catchError((error) => {
            this.setPropertiesOnCatchError(
              error.message || 'Failed to load properties.'
            );
            return of(error);
          })
        )
      )
    );
  });

  readonly onPageChange = this.updater((state, page: number) => ({
    ...state,
    filter: {
      ...state.filter,
      page,
    },
  }));

  readonly onFilterChange = this.updater(
    (state, prop: { sortKey: string[]; sortOrder: ('asc' | 'desc')[] }) => ({
      ...state,
      filter: {
        ...state.filter,
        ...prop,
      },
    })
  );

  get properties() {
    return this.selectSignal((state) => {
      const {
        data: items,
        firstItemOnPage,
        lastItemOnPage,
        totalItemCount,
        loading,
      } = state.list;
      const data = items ? items : [];
      return {
        data,
        firstItemOnPage,
        lastItemOnPage,
        totalItemCount,
        loading,
        page: state.filter.page,
        sortKey: state.filter.sortKey,
        sortOrder: state.filter.sortOrder,
      };
    });
  }
}
