<div class="details-area-container" *ngIf="header.details.visible">
  <dashboard-breadcrumbs
    *ngIf="header.details.breadcrumbs?.length"
    [breadcrumbs]="header.details.breadcrumbs"
  >
  </dashboard-breadcrumbs>
  <dashboard-details-area-title
    *ngIf="header.details.title || header.details.subtitle || header.details.status"
    [title]="header.details.title"
    [subtitle]="header.details.subtitle"
    [status]="header.details.status"
  >
  </dashboard-details-area-title>
  <div
    class="main-area"
    *ngIf="
      header.details.rightActionButtons.length ||
      header.details.attributes.length
    "
  >
    <div class="attributes-area">
      <dashboard-details-area-attributes
        *ngIf="header.details.attributes.length"
        [attributes]="header.details.attributes"  
      >
      </dashboard-details-area-attributes>
    </div>
    <div class="right-action-buttons">
      <ng-container *ngFor="let button of header.details.rightActionButtons">
        <p-button
          [label]="button.label"
          [outlined]="button.outlined"
          [rounded]="button.rounded"
          [severity]="button.severity"
          [disabled]="button.disabled"
          [raised]="button.raised"
          [loading]="button.loading"
          size="small"
          styleClass="details-area-button"
          (onClick)="handleActionButton(button)"
        >
          <ng-template pTemplate="icon" *ngIf="button.icon?.length">
            <span class="material-icons right-action-icon">
              {{button.icon}}
            </span>
          </ng-template>
        </p-button>
      </ng-container>
    </div>
  </div>
  <dashboard-details-area-tab-menu
    *ngIf="header.details.tabs.length"
    [items]="header.details.tabs"
    [activeTab]="header.details.activeTab"
  >
  </dashboard-details-area-tab-menu>
</div>
