export const generatePropertyDetailsTab = (permissions: string[]) => {
  return ([
    {
      label: 'Property Overview',
      value: 'overview'
    },
    permissions.includes('property:property-financials:view') && {
      label: 'Property Financials',
      value: 'property-financials'
    },
    permissions.includes('property:site-financials:view') && {
      label: 'Site Financials',
      value: 'site-financials'
    }
  ]).filter((tab) => !!tab);
};
