import { inject, Injectable } from "@angular/core";
import { Phases, PhaseChange, PasswordRecoveryService, AuthService } from "@velocity/login";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, map, noop, Observable, of, switchMap, tap } from "rxjs";
import { MessageService } from "primeng/api";
import { AppStateService, BaseHttpErrorDto } from "@velocity/common";
import { Router } from "@angular/router";

export interface LoginState {
  loading: boolean;
  currentPhase: Phases;
  formValues: {
    username: string;
    password: string;
    forgotPasswordEmail: string;
    recoveryCode: string;
    newPassword: string;
  };
}

export const initialState: LoginState = {
  loading: false,
  currentPhase: Phases.Username,
  formValues: {
    username: '',
    password: '',
    forgotPasswordEmail: '',
    recoveryCode: '',
    newPassword: ''
  }
};

@Injectable({
  providedIn: 'root'
})
export class LoginStore extends ComponentStore<LoginState> {
  private appState = inject(AppStateService);
  private authService = inject(AuthService);
  private passwordRecoveryService = inject(PasswordRecoveryService);
  private messageService = inject(MessageService);
  private router = inject(Router);

  constructor() {
    super(initialState);
  }

  readonly setLoading = this.updater((state, value: boolean) => ({
    ...state,
    loading: value
  }));

  readonly setCurrentPhase = this.updater((state, value: Phases) => ({
    ...state,
    loading: false,
    currentPhase: value
  }));

  readonly setFormValues = this.updater((state, value: PhaseChange) => ({
    ...state,
    formValues: {
      ...state.formValues,
      ...value.formData
    }
  }));

  readonly clearFormAndContinue = this.updater((state, phase: Phases) => ({
    ...state,
    currentPhase: phase,
    loading: false,
    formValues: {
      username: '',
      password: '',
      forgotPasswordEmail: '',
      recoveryCode: '',
      newPassword: ''
    }
  }));

  readonly onPhaseChange = this.effect((value$: Observable<PhaseChange>) => {
    return value$.pipe(
      tap((value) => {
        this.setFormValues(value);
      }),
      map((value) => this.handlePhaseChange(value))
    );
  });

  readonly onProcessPhase = this.effect((value$: Observable<{ nextPhase: Phases, currentPhase: Phases, state: LoginState }>) => {
    return value$.pipe(
      tap((value) => {
        switch (value.currentPhase) {
          case Phases.Username: {
            return this.onVerifyEmail(value);
          }
          case Phases.Password: {
            return this.onPasswordLogin(value);
          }
          case Phases.ForgotPassword: {
            return this.onSendRecoveryCode(value);
          }
          case Phases.RecoveryCode: {
            return this.onValidateRecoveryCode(value);
          }
          case Phases.ResetPassword: {
            return this.onResetPassword(value);
          }
        }

        return noop;
      })
    );
  });

  readonly onResetPassword = this.effect((value$: Observable<{ nextPhase: Phases, currentPhase: Phases, state: LoginState }>) => {
    return value$.pipe(
      tap(() => {
        this.setLoading(true);
      }),
      switchMap((value) => 
        this.passwordRecoveryService.resetPassword(
          value.state.formValues.forgotPasswordEmail,
          value.state.formValues.newPassword
        ).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              detail: 'Password changed successfully'
            });

            this.clearFormAndContinue(value.nextPhase);
          }),
          catchError(this.handleLoginRequestError)
        )
      )
    );
  });

  readonly onValidateRecoveryCode = this.effect((value$: Observable<{ nextPhase: Phases, currentPhase: Phases, state: LoginState }>) => {
    return value$.pipe(
      tap(() => {
        this.setLoading(true);
      }),
      switchMap((value) => 
        this.passwordRecoveryService.validateResetPasswordCode(
          value.state.formValues.forgotPasswordEmail,
          value.state.formValues.recoveryCode
        ).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              detail: 'Account is verified'
            });
            this.setCurrentPhase(value.nextPhase);
          }),
          catchError(this.handleLoginRequestError)
        )
      )
    );
  });

  readonly onSendRecoveryCode = this.effect((value$: Observable<{ nextPhase: Phases, currentPhase: Phases, state: LoginState }>) => {
    return value$.pipe(
      tap(() => {
        this.setLoading(true);
      }),
      switchMap(
        (value) => this.passwordRecoveryService.requestPasswordReset(
          value.state.formValues.forgotPasswordEmail
        ).pipe(
          tap(() => {
            this.setCurrentPhase(value.nextPhase);
            this.messageService.add({
              severity: 'success',
              detail: `Account recovery email sent to ${value.state.formValues.forgotPasswordEmail}`
            })
          }),
          catchError(this.handleLoginRequestError)
        )
      )
    );
  });

  readonly onPasswordLogin = this.effect((value$: Observable<{ nextPhase: Phases, currentPhase: Phases, state: LoginState }>) => {
    return value$.pipe(
      tap(() => {
        this.setLoading(true);
      }),
      switchMap(
        (value) => this.authService.loginWithPassword(
          value.state.formValues.username,
          value.state.formValues.password
        ).pipe(
          tap((response) => {
            this.appState.setAccessToken(response.data.token);
            this.setCurrentPhase(value.nextPhase);
            this.messageService.add({
              severity: 'success',
              detail: response.message
            });
            this.router.navigateByUrl('/');
          }),
          catchError(this.handleLoginRequestError)
        )
      )
    )
  });

  readonly onVerifyEmail = this.effect((value$: Observable<{ nextPhase: Phases, currentPhase: Phases, state: LoginState }>) => {
    return value$.pipe(
      tap(() => {
        this.setLoading(true);
      }),
      switchMap(
        (value) => this.authService.getSSOProviderByEmail(
          value.state.formValues.username
        ).pipe(
          tap(() => {
            this.setCurrentPhase(value.nextPhase);
          }),
          catchError(this.handleLoginRequestError)
        )
      )
    )
  });

  handlePhaseChange(value: PhaseChange) {
    if (value.isBack || [Phases.ForgotPassword].includes(value.next)) {
      return this.setCurrentPhase(value.next);
    }

    if (value.resendResetPasswordCode) {
      return this.onSendRecoveryCode({
        nextPhase: value.next,
        currentPhase: (this.selectSignal((state) => state.currentPhase))(),
        state: (this.selectSignal((state) => state))()
      });
    }

    return this.processFormData(value.next);
  }

  processFormData(nextPhase: Phases) {
    return this.onProcessPhase({
      state: (this.selectSignal((state) => state))(),
      nextPhase,
      currentPhase: (this.selectSignal((state) => state.currentPhase))()
    });
  }


  handleLoginRequestError = (error: BaseHttpErrorDto, caught: Observable<unknown>) => {
    this.setLoading(false);

    this.messageService.add({
      severity: 'error',
      detail: error.description
    });

    return of(caught);
  };

  get formValues() {
    return this.selectSignal((state) => state.formValues);
  }

  get currentPhase() {
    return this.selectSignal((state) => state.currentPhase);
  }

  get loading() {
    return this.selectSignal((state) => state.loading);
  }
}
