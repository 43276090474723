
<div class="title-area">
  <typography-header
    *ngIf="title"
    [h]="6"
    [label]="title"
  >
  </typography-header>
  <typography-header
    *ngIf="subtitle"
    [h]="6"
    [label]="subtitle"
    class="subtitle"
  >
  </typography-header>
  <div *ngIf="status" class="status">
    <p-tag [severity]="status.severity" [value]="status.label"></p-tag>
  </div>
</div>
