<div class="container">
  <typography-header label="Check your inbox" [h]="4" [bold]="false"></typography-header>
  <typography-p
    label="Enter the 6-digits verification code sent to"
    p="body2"
    class="text-color"
  >
  </typography-p>
  <typography-p
    [label]="maskedEmail"
    p="body2"
    class="text-color"
  >
  </typography-p>
  <div class="input-container">
    <p-inputOtp
      [length]="6"
      [integerOnly]="true"
      class="otp"
      variant="filled"
      [formControl]="recoveryCodeForm"
    />
  </div>
  <div class="input-container button-margin">
    <button
      pButton
      label="Submit"
      [raised]="true"
      [loading]="loading"
      [disabled]="disabled || !isValid"
      (click)="handleSubmit()"
    >
    </button>
  </div>
  <typography-p
    label="Did not received verification code?"
    [bold]="true"
    p="subtitle2"
    class="text-color"
  >
  </typography-p>
  <p-button
    label="Request a new one"
    [link]="true"
    [disabled]="disabled || loading"
    (onClick)="handleResendCode()"
  >
  </p-button>
</div>
