import { inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ENVIRONMENT } from '../../provider-tokens';
import { HttpService } from '../http.service';
import { BaseHttpErrorDto, BaseHttpResponseDto, PropertyLandAcrDto, PropertyLandAcrReportResponseDto } from '../../types';


@Injectable({
  providedIn: 'root'
})
export class PropertyLandAcrApi {
  private http = inject(HttpService);
  private environment = inject(ENVIRONMENT);

  private baseUrl = `${this.environment.apiUrl}/api/PropertyLandAcr`;
 
  getPropertyListAcrReporting(propertyId: number) {
    const params = new HttpParams()
      .set('propertyId', propertyId.toString());

    return this.http.get<
      BaseHttpResponseDto<PropertyLandAcrDto[],BaseHttpErrorDto[]>
    >(`${this.baseUrl}/list`, params);
  }

  getPropertyAcrReporting(propertyId: number, transactionIds = '') {
    const params = new HttpParams()
      .set('transactionIds', transactionIds.toString())
      .set('propertyId', propertyId.toString());

    return this.http.get<
      BaseHttpResponseDto<PropertyLandAcrReportResponseDto[], BaseHttpErrorDto[]>
    >(`${this.baseUrl}`, params);
  }
}
