<p-dialog [(visible)]="open" [modal]="true">
  <ng-template pTemplate="headless">
    <div class="user-idle-dialog">
      <div class="header-area">
        <span class="pi pi-lock"></span>
        {{header}}
      </div>
      <div class="content-area">
        {{dynamicDescription}}
      </div>
      <div class="footer-area">
        <p-button
          label="Log out"
          [outlined]="true"
          (onClick)="handleLogoutClick()"
        />
        <p-button
          label="Stay logged in"
          [raised]="true"
          [severity]="severity"
          (onClick)="handleResetTimer()"
        />
      </div>
    </div>
  </ng-template>
</p-dialog>
