<video autoplay loop [muted]="true" class="login-background">
  <source src="{{backgroundVideo}}" />
</video>
<div class="login-container">
  <div class="login-spacer"></div>
  <div class="login-spacer-2">
    <div class="login-area">
      <div class="login-form-container">
        <div class="login-form-logo">
          <img [src]="logo" alt="logo" height="70px">
        </div>
        <form class="login-form">
          <ng-container [ngSwitch]="currentPhase">
            <ng-container *ngSwitchCase="phases.Username">
              <login-username-phase
                [loading]="loading"
                [username]="formValues.username"
                (phaseChange)="handlePhaseChange($event)"
              >
              </login-username-phase>
            </ng-container>
            <ng-container *ngSwitchCase="phases.Password">
              <login-password-phase
                [loading]="loading"
                [username]="formValues.username"
                [password]="formValues.password"
                (phaseChange)="handlePhaseChange($event)"
              >
              </login-password-phase>
            </ng-container>
            <ng-container *ngSwitchCase="phases.ForgotPassword">
              <login-forgot-password-phase
                [loading]="loading"
                [forgotPasswordEmail]="formValues.forgotPasswordEmail"
                (phaseChange)="handlePhaseChange($event)"
              >
              </login-forgot-password-phase>
            </ng-container>
            <ng-container *ngSwitchCase="phases.RecoveryCode">
              <login-recovery-code-phase
                [email]="formValues.forgotPasswordEmail"
                [loading]="loading"
                (phaseChange)="handlePhaseChange($event)"
              >
              </login-recovery-code-phase>
            </ng-container>
            <ng-container *ngSwitchCase="phases.ResetPassword">
              <login-reset-password-phase
                [loading]="loading"
                (phaseChange)="handlePhaseChange($event)"
              >
              </login-reset-password-phase>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</div>
