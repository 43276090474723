import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PrimeNgFramework, TypographyHeaderComponent, TypographyPComponent } from "@velocity/common";
import { Button } from "primeng/button";

@Component({
  selector: 'dashboard-user-idle-dialog',
  styleUrl: './user-idle-dialog.component.scss',
  templateUrl: './user-idle-dialog.component.html',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyHeaderComponent,
    TypographyPComponent
  ]
})
export class DashboardUserIdleDialogComponent {
  @Input() header = 'Session Idle';
  @Input() description = 'You will be logged out in {time} seconds due to inactivity. Please click "Stay Logged In" button within the allowed time to remain logged in.';
  @Input() currentTime = 99;
  @Input() severity: Button['severity'] = 'primary';
  @Input() open = false;
  @Output() resetTimer = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter<boolean>();


  get dynamicDescription() {
    return this.description.replace('{time}', this.currentTime.toString());
  }

  handleResetTimer() {
    this.resetTimer.emit(true);
  }

  handleLogoutClick() { 
    this.logout.emit(true);
  }
}
