import { Component, inject } from '@angular/core';
import {
  PrimeNgFramework,
  DropdownOption
} from '@velocity/common';
import { PropertyDetailsStore } from '../../property-details.store';
import {
  PropertyDetailsPropertyFinancialsLayoutComponent
} from '@velocity/property';

@Component({
  selector: 'property-details-financials-tab',
  standalone: true,
  imports: [
    PrimeNgFramework,
    PropertyDetailsPropertyFinancialsLayoutComponent
],
  providers: [
  ],
  templateUrl: './property-financials.component.html',
  styleUrls: ['./property-financials.component.scss']
})
export class PropertyDetailsFinancialsTabComponent {  
  store = inject(PropertyDetailsStore);

  handleOptionUpdate(event: DropdownOption[]) {
    this.store.updateSelectedPropertyFilter(event);
  }

}
