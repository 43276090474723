<div class="card">
  <p-table
    [value]="tableData"
    [scrollable]="scrollable"
    [scrollHeight]="scrollHeight"
    [columns]="columns"
    [scrollable]="scrollable"
    [customSort]="customSort"
    [styleClass]="styleClass"
    [reorderableColumns]="reorderableColumns"
    [showCurrentPageReport]="showCurrentPageReport"
    [loading]="loading"
    (sortFunction)="onCustomSort($event)"
    (onPage)="onPageChange($event)"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngIf="reorderableColumns" style="width: 3rem"></th>
        <th
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="
            hideColumnSort || disabledSortColumn.includes(col.field)
          "
        >
          {{ col.header }}
          <p-columnFilter
            *ngIf="!hideColumnFilter"
            type="text"
            [field]="col.field"
            display="menu"
          />
          <p-sortIcon
            *ngIf="!hideColumnSort && !disabledSortColumn.includes(col.field)"
            [field]="col.field"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-index="rowIndex"
    >
      <tr [pReorderableRow]="index">
        <td *ngIf="reorderableColumns">
          <span class="pi pi-bars" pReorderableRowHandle></span>
        </td>
        <td *ngFor="let col of columns">
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    class="custom-paginator-table"
    currentPageReportTemplate="{first} - {last} of {totalRecords}"
    *ngIf="paginator"
    (onPageChange)="onPageChange($event)"
    [first]="first"
    [rows]="rows"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [showCurrentPageReport]="true"
  />
</div>
