import { formatDate, formatLink, formatNumeric } from "@velocity/common";

export const propertyInfoKeys = {
  propertyAccountId: 'Property ID (PID)',
  propertyName: 'Property Name',
  addressLine1: 'Full Address',
  size: {
    type: 'numeric',
    key: 'Property Size (SF)',
    addon: 'SF',
    formatter: formatNumeric
  },
  landAreaAcres: 'Land Area (Acres)',
  businessPark: 'Business Park',
  threeWordKey: {
    type: 'link',
    key:  'Three Word Key',
    addon: 'https://what3words.com/{url}',
    formatter: formatLink
  },
  longitude: 'Longitude',
  latitude: 'Latitude',
  propertyUse: 'Property Use',
  tailWinds: 'TailWinds',
  portfolioClassification: 'Portfolio Classification',
  acquisitionDate: {
    type: 'date',
    key: 'Acquistion Date',
    formatter: formatDate
  },
  inServicePeriod: 'In Service Period',
  assetStatus: 'Asset Status',
  reportingStatus: 'Reporting Status',
  region: 'Region',
  subRegion: 'Subregion',
  propertyReportingType: 'Property Reporting Type',
  propertyAccountingPortfolio: 'Property Accounting Portfolio',
  buildingClass: 'Building Class',
  operationsStaffLevel: 'Operations Staff Level',
  serviceLevel: 'Service Level',
  // TODO: add RE Taxes, RE Taxes wrong key in velocity
  realEstateTaxStatus: 'Real Estate Tax Status',
  corporateCode: 'Corporate Code',
  accountingConstruction: 'Accounting Construction',
  pctowned: 'BX Ownership %'
};
