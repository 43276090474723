<collapsible-card
  icon="collections"
  title="PROPERTY GALLERY"
>
  <ng-container slot="header-action">
    <p-button [text]="true">
      <span class="submit-pictures-button">
        <span class="material-icons">
          edit
        </span>
        <typography-p p="caption">
          Submit pictures
        </typography-p>
      </span>
    </p-button>
  </ng-container>
  <ng-container slot="content">
    <media-carousel
      class="content"
      [media]="data"
      [visibleThumbnails]="4"
    >
    </media-carousel>
  </ng-container>
</collapsible-card>
