import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TypographyPComponent } from '../typography-p/typography-p.component';
import { PrimeNgFramework } from '../../modules/primeng-framework';

@Component({
  selector: 'common-footer',
  standalone: true,
  styleUrl: './footer.component.scss',
  templateUrl: './footer.component.html',
  imports: [
    TypographyPComponent,
    PrimeNgFramework
  ],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  @Input() footerText = '© Velocity 0.0.0';
  @Input() supportEmail = 'support@velocity.properties';
}
