import { AbstractControl } from '@angular/forms';

export class LoginValidators {

  static requireNumeric(control: AbstractControl) {
    if (!/[0-9]+/.test(control.value)) {
      return { numeric: true };
    }
    return null;

  }

  static requireSpecialCharacter(control: AbstractControl) {
    if (!/[`!@#$%^&*()+-]/.test(control.value)) {
      return { specialCharacter: true };
    }
    return null;
  }

  static requireUpperCase(control: AbstractControl) {
    if (!/[A-Z]+/.test(control.value)) {
      return { capitalCase: true };
    }
    return null;
  }

  static requireLowerCase(control: AbstractControl) {
    if (!/[a-z]+/.test(control.value)) {
      return { smallCase: true };
    }
    return null;
  }

  static require8AsMinLength(control: AbstractControl) {
    if (control.value.length < 8) {
      return { minimum: true };
    }
    return null;
  }
}
