<div class="container">
  <p-floatLabel>
    <p-password 
      [toggleMask]="true"
      [feedback]="false"
      inputStyleClass="full-width"
      styleClass="full-width"
      [formControl]="passwordForm"
      name="password"
      required
    />
    <label for="password">Password</label>
    <small
      class="p-error block"
      *ngIf="passwordForm.invalid && passwordForm.dirty"
    >
      Password is required
    </small>
  </p-floatLabel>
  <span class="forgot-password">
    <p-button
      label="Forgot Password?"
      [link]="true"
      (onClick)="handleForgotPasswordPhase()"
      [disabled]="disabled"
    />
  </span>
  <button
    pButton
    label="Sign in"
    [raised]="true"
    (click)="handleSubmit()"
    [disabled]="disabled"
    [loading]="loading"
  >
  </button>
  <button
    pButton
    label="Back"
    [outlined]="true"
    (click)="handleBackPhase()"
    [disabled]="disabled"
  >
  </button>
</div>
