<collapsible-card
  [icon]="icon"
  [title]="title"
>
  <ng-container slot="content">
    <div class="content-container">
      <key-value-list [items]="items"></key-value-list>
    </div>
  </ng-container>
</collapsible-card>
