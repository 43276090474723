import { Component, inject } from '@angular/core';
import { PrimeNgFramework } from '@velocity/common';
import { PropertyDetailsOverviewLayoutComponent } from '@velocity/property';
import { PropertyDetailsStore } from '../../property-details.store';

@Component({
  selector: 'property-details-overview-tab',
  standalone: true,
  imports: [
    PrimeNgFramework,
    PropertyDetailsOverviewLayoutComponent
  ],
  providers: [
  ],
  templateUrl: './property-overview.component.html',
  styleUrls: ['./property-overview.component.scss']
})
export class PropertyDetailsOverviewTabComponent {
  store = inject(PropertyDetailsStore);
}
