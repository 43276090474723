import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DashboardNavigationComponent } from "../components/navigation/navigation.component";
import { Account, DerivedHeader, PropertyDto, UserInfo } from '@velocity/common';
import { DashboardDetailsAreaComponent } from "../components/details-area/details-area.component";
import { RouterOutlet } from "@angular/router";
import { RightActionButtonPayload, FooterComponent } from "@velocity/common";

@Component({
  selector: 'dashboard-layout',
  standalone: true,
  styleUrl: './dashboard-layout.component.scss',
  templateUrl: './dashboard-layout.component.html',
  imports: [
    DashboardNavigationComponent,
    DashboardDetailsAreaComponent,
    RouterOutlet,
    FooterComponent
  ]
})
export class DashboardLayoutComponent {
  @Input() logoShort!: string;
  @Input() logo!: string;
  @Input() user!: UserInfo;
  @Input() header!: DerivedHeader;

  @Output() logout = new EventEmitter<boolean>();
  @Output() search = new EventEmitter<string>();
  @Output() accountChange = new EventEmitter<Account>();
  @Output() rightActionButton = new EventEmitter<Omit<RightActionButtonPayload, 'pageIdentifier'>>();
  @Output() searchResultSelect = new EventEmitter<PropertyDto>();

  handleAccountChange(account: Account) {
    this.accountChange.emit(account);
  }
  
  handleActionButton(payload: Omit<RightActionButtonPayload, 'pageIdentifier'>) {
    this.rightActionButton.emit(payload);
  }

  handleSearch(searchKey: string) {
    this.search.emit(searchKey);
  }

  handleSearchResultSelect(value: PropertyDto) {
    this.searchResultSelect.emit(value);
  }

  handleLogout() {
    this.logout.emit(true);
  }
}
