import { Component, Input } from '@angular/core';
import {
  CollapsibleCardComponent,
  CollapsibleInformationCardComponent,
  KeyValueDetails,
  PrimeNgFramework,
  StoreAssignmentDto,
  Permissions,
  MediaCarouselItem
} from '@velocity/common';
import { PropertyAssignmentUserRoleCardListComponent } from '../../components/assignment-user-role-card-list/assignment-user-role-card-list.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PropertyGalleryComponent } from '../../components/property-gallery/property-gallery.component';

@Component({
  selector: 'property-details-overview-layout',
  standalone: true,
  imports: [
    PropertyGalleryComponent,
    PropertyAssignmentUserRoleCardListComponent,
    CollapsibleInformationCardComponent,
    CollapsibleCardComponent,
    PrimeNgFramework,
    NgxPermissionsModule
  ],
  templateUrl: './property-details-overview-layout.component.html',
  styleUrls: ['./property-details-overview-layout.component.scss']
  
})
export class PropertyDetailsOverviewLayoutComponent {
  permissions = Permissions;
  @Input() loading = true;
  @Input() media: MediaCarouselItem[] = [];
  @Input() propertyInfo: KeyValueDetails[] = [];
  @Input() ownershipInfo: KeyValueDetails[] = [];
  @Input() marketInfo: KeyValueDetails[] = [];
  @Input() assignedUsers: StoreAssignmentDto[] = [];
}
