import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PrimeNgFramework, TypographyPComponent } from '@velocity/common';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'dashboard-navigation-menu',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgFramework,
    TypographyPComponent
  ],
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavigationMenuComponent {
  @Input() menu: MenuItem[] = [];
}
