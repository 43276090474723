import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormControl, Validators } from '@angular/forms';
import { 
  PrimeNgFramework,
  TypographyHeaderComponent,
  TypographyPComponent,
  maskEmail
} from '@velocity/common';
import { PhaseChange } from '../../types/phase-change';
import { Phases } from '../../enums/login-phases';

@Component({
  selector: 'login-recovery-code-phase',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TypographyHeaderComponent,
    TypographyPComponent
  ],
  templateUrl: './login-recovery-code-phase.component.html',
  styleUrls: ['./login-recovery-code-phase.component.scss']
})
export class LoginRecoveryCodePhaseComponent implements OnChanges {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() email!: string;
  @Output() phaseChange = new EventEmitter<PhaseChange>();

  recoveryCodeForm = new FormControl<string>('');

  constructor() {
    this.recoveryCodeForm.setValue('');
    this.recoveryCodeForm.setValidators([Validators.required]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading']) {
      const isLoading = changes['loading']?.currentValue;
      this.recoveryCodeForm[isLoading ? 'disable' : 'enable']();
    }
  }

  get isValid() {
    return this.recoveryCodeForm.value?.length === 6;
  }

  get maskedEmail() {
    return maskEmail(this.email);
  }

  handleSubmit() {
    this.phaseChange.emit({
      formData: {
        recoveryCode: this.recoveryCodeForm.value
      },
      next: Phases.ResetPassword
    });
  }

  handleResendCode() {
    this.phaseChange.emit({
      formData: {},
      resendResetPasswordCode: true,
      next: Phases.RecoveryCode
    });
  }
}
