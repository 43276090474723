import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DropdownOption,
  PanelCardComponent, 
  PrimeNgFramework, 
  SearchableDropdownComponent, 
  TypographyHeaderComponent, 
  TypographyPComponent,
  Permissions
} from '@velocity/common';
import { FinancialsTableComponent } from '../../components/financials-table/financials-table.component';
import { FinancialDataGroup, FinancialGroupTotal } from '../../types';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';


@Component({
  selector: 'property-details-site-financials-layout',
  standalone: true,
  imports: [
    FinancialsTableComponent,
    PanelCardComponent,
    SearchableDropdownComponent,
    TypographyPComponent,
    CommonModule,
    PrimeNgFramework,
    TypographyHeaderComponent,
    NgxPermissionsModule
  ],
  templateUrl: './property-details-site-financials-layout.component.html',
  styleUrls: ['./property-details-site-financials-layout.component.scss']
})
export class PropertyDetailsSiteFinancialsLayoutComponent {
  @Input() asOf = '';
  @Input() loading = false;
  @Input() tableDataLoading = false;
  @Input() tableData: FinancialDataGroup[] = [];
  @Input() tableDataTotal: FinancialGroupTotal[] = [];
  @Input() propertyList: DropdownOption[] = [];
  @Input() propertyListLoading = true;
  @Input() selectedProperties: DropdownOption[] = [];
  @Output() propertyListOptionSelect = new EventEmitter<DropdownOption[]>();

  permissions = Permissions;

  handlePropertyListOptionSelect(event: DropdownOption[]) {
    this.propertyListOptionSelect.emit(event);
  }
}
