import { GenericAttributeFormatter, GenericKeys, GenericObject } from "../../types";

export const formatAttributeKeys = (
  keyMap: GenericKeys,
  data: GenericObject
) => {
  return Object.entries(keyMap).map(([attributeKey, optionOrFormattedKey]) => {
    let value = (data?.[attributeKey] ?? '-') as string;
    let type = 'text';
    let url = '';

    if (typeof optionOrFormattedKey === 'string') {
      return {
        key: optionOrFormattedKey,
        value,
        type,
        url
      };
    }

    if (!data?.[attributeKey]) {
      return {
        key: optionOrFormattedKey.key,
        value,
        type,
        url
      };
    }

    const formattedValue = format(optionOrFormattedKey, value);

    if (optionOrFormattedKey.type === 'link') {
      type = 'link';
      url = formattedValue as string;
    } else {
      value = formattedValue ?? '-';
    }

    return {
      key: optionOrFormattedKey.key,
      value,
      type,
      url
    };
  })
};

const format = (options: GenericAttributeFormatter, value: string | number | null) => {
  return options.formatter(value, options.addon);
}

