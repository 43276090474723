// TODO
import { UserProfileDto } from "../../types";

export class PermissionHandler {
  getPermissions(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    user: UserProfileDto,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    permissions: string[]
  ): string[] {
    return [];
  }
}
