import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, combineLatest, distinctUntilChanged, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AuthApi, PropertyApi, UserProfileApi } from '../apis';
import { AppStateActionList } from './app-state-actions.enum';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../types/app-state';
import { selectCurrentUrl, selectPermissions, selectRole, selectUpdateProfilePayload, selectUser } from './app-state.feature';
import * as AppStateActions from './app-state.actions';
import { StorageService } from '../storage';
import { ENVIRONMENT } from '../../provider-tokens';
import { jwtDecode } from 'jwt-decode';
import { DecodedPermissionToken } from '../../types/decoded-permission-token';
import { DecodedUserToken, UserProfileDto } from '../../types';
import { PermissionHandler } from '../permission';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';

export class AppStateEffects {
  private propertyApi = inject(PropertyApi);
  private userProfileApi = inject(UserProfileApi);
  private authApi = inject(AuthApi);
  private messageService = inject(MessageService);
  private store = inject(Store<AppState>);
  private router = inject(Router);
  private storage = inject(StorageService);
  private ngxPermissionService = inject(NgxPermissionsService);
  private permissionHandler = inject(PermissionHandler);
  private environment = inject(ENVIRONMENT);

  onUserAccountChange$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(AppStateActionList.user_onChangeAccount),
        withLatestFrom(
          this.store.select(selectUser),
          this.store.select(selectUpdateProfilePayload)
        ),
        // eslint-disable-next-line
        switchMap(([_, user, updatePayload]) =>
          this.userProfileApi.updateUserProfile(
            updatePayload as UserProfileDto
          ).pipe(
            tap(() => {
              this.store.dispatch(AppStateActions.clearUserDetails());
            }),
            /** TODO: add error handling */
            map(() => {
              this.ngxPermissionService.flushPermissions();
              this.router.navigateByUrl('/');
              return AppStateActions.onBasicUserDetailsResolve({
                userId: `${user.details.value.userId}`,
                accountId: user.details.value.accountId ?? 0
              });
            })
          )
        )
      );
    }
  );

  loadBasicUserDetails$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(AppStateActionList.user_loadBasicUserDetails),
        switchMap(() => 
          this.userProfileApi.getUserProfileToken().pipe(
            map((response) => {
              if (response.errors) {
                return AppStateActions.onBasicUserDetailsFailure({
                  errorMessage: response.errors[0]?.description 
                    ?? 'Session expired. Please login.'
                });
              }
              const jwt = jwtDecode<DecodedUserToken>(response.data);
  
              return AppStateActions.onBasicUserDetailsResolve({
                userId: jwt.userId,
                accountId: parseInt(jwt.accountId)
              });
            }),
            catchError(() => 
              of({
                type: AppStateActionList.user_onBasicUserDetailsFailure,
                errorMessage:'Session expired. Please login.'
              })
            )
          )
        )
      );
    },
    {}
  );

  onFailures$ = createEffect(
    () => inject(Actions).pipe(
      ofType(
        AppStateActionList.user_onBasicUserDetailsFailure,
        AppStateActionList.user_onDetailsFailure,
        AppStateActionList.user_onPermissionsFailure
      ),
      tap((payload) => {
        this.messageService.add({
          severity: 'error',
          detail: payload.errorMessage
        });
        this.router.navigateByUrl('/login');
      })
    ),
    { dispatch: false }
  );

  onLogout$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(AppStateActionList.user_logout),
        switchMap(() => this.authApi.logout().pipe(
          tap(() => {
            this.storage.remove(this.environment.tokenName);
            this.router.navigateByUrl('/login');
            this.messageService.add({
              severity: 'success',
              detail: 'User successfully logged out'
            });
          }),
          map(() => {
            return {
              type: AppStateActionList.user_clearDetails
            }
          })
        ))
      )
    }
  )

  loadUserDetails$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(AppStateActionList.user_onBasicUserDetailsResolve),
        withLatestFrom(this.store.select(selectUser)),
        // eslint-disable-next-line
        switchMap(([_action, user]) => 
          this.userProfileApi.getUserDetails(
            user.details.value.userId ?? '',
            user.details.value.accountId?.toString() ?? ''
          ).pipe(
            map((response) => {
              if (response.errors) {
                return {
                  type: AppStateActionList.user_onDetailsFailure,
                  errorMessage: response.errors[0]?.description 
                      ?? 'Session expired. Please login.'
                };
              }

              // exclude accountId from details endpoint
              // eslint-disable-next-line
              const { accountId, ...rest } = response.data;
  
              return {
                type: AppStateActionList.user_onDetailsResolve,
                payload: rest
              }
            }),
            catchError(() => 
              of({
                type: AppStateActionList.user_onDetailsFailure,
                errorMessage:'Session expired. Please login.'
              })
            )
          )
        )
      );
    }
  );

  loadPermissions$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(AppStateActionList.user_onBasicUserDetailsResolve),
        withLatestFrom(this.store.select(selectUser)),
        // eslint-disable-next-line
        switchMap(([_, user]) => 
          this.authApi.getPermissionsToken(
            user.details.value.userId ?? '',
            user.details.value.accountId?.toString() ?? ''
          ).pipe(
            map((response) => {
              if (response.errors) {
                return {
                  type: AppStateActionList.user_onPermissionsFailure,
                  errorMessage: response.errors[0]?.description 
                      ?? 'Session expired. Please login.'
                };
              }
  
              const jwt = jwtDecode<DecodedPermissionToken>(response.data);

              return {
                type: AppStateActionList.user_onPermissionsResolve,
                permissions: jwt.role
              }
            }),
            catchError(() => 
              of({
                type: AppStateActionList.user_onPermissionsFailure,
                errorMessage:'Session expired. Please login.'
              })
            )
          )
        )
      );
    }
  );

  onHeaderPropertySearch$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(AppStateActionList.header_propertySearch),
        withLatestFrom(this.store.select(selectUser)),
        switchMap(([{ searchKey }, user]) => this.propertyApi.getProperty({
          sortKey: '',
          searchString: searchKey,
          page: '1',
          pageSize: '2000',
          accountId: user.details.value.accountId?.toString()
        }).pipe(
          map((value) => {
            return {
              type: AppStateActionList.header_propertySearchResolve,
              payload: value
            };
          }),
            catchError(() => 
              of({
                type: AppStateActionList.header_propertySearchFailure,
                errorMessage:'Could not search this time, please try again later.'
              })
            )
        ))
      )
    }
  );

  onPermissionsAndDetailsLoaded$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(
          AppStateActionList.user_onDetailsResolve,
          AppStateActionList.user_onPermissionsResolve
        ),
        withLatestFrom(this.store.select(selectUser)),
        filter(([, user]) => 
          !!user.details.value.userAccountDto?.length &&
          !!user.permissions.value.length
        ),
        map(([, user]) => {
          const unfilteredPermissions = user.permissions.value;
          const permissions = this.permissionHandler.getPermissions(
            user.details.value as UserProfileDto,
            unfilteredPermissions
          );

          this.ngxPermissionService.loadPermissions(permissions);

          return {
            type: AppStateActionList.user_setPermissions,
            permissions: permissions
          }
        })
      )
    }
  );

   setPreviousPage$ = createEffect(
    () => {
      return inject(Actions).pipe(
        ofType(ROUTER_NAVIGATED),
        map((navigated) => {
          const currentUrl = navigated.payload.event.url;

          return {
            type: AppStateActionList.app_changePreviousUrl,
            previousUrl: currentUrl
          }
        })
      )
    }
  );

  /** redirect user's initial page based on role */
  handlePageRedirect$ = createEffect(
    () => {
      return combineLatest([
        this.store.select(selectCurrentUrl),
        this.store.select(selectPermissions),
        this.store.select(selectRole)
      ]).pipe(
        distinctUntilChanged(),
        filter(([, permissions, role]) => !!permissions.length && !!role),
        tap(([currentUrl, , role]) => {
          // TODO: navigate roles properly
          if (currentUrl !== '/') {
            return;
          }

          if (['BROKER'].includes(role as string)) {
            this.router.navigateByUrl('/transactions');
          }

          this.router.navigateByUrl('/properties');
        })
      );
    },
    { dispatch: false }
  );
}
