import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends StorageService {
  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string, defaultValue = '') {
    const value = localStorage.getItem(key);

    return value ? value : defaultValue;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}
