import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, Validators, FormControl } from '@angular/forms';
import { PhaseChange } from '../../types/phase-change';
import { Phases } from '../../enums/login-phases';
import { PrimeNgFramework } from '@velocity/common';

@Component({
  selector: 'login-password-phase',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './login-password-phase.component.html',
  styleUrls: ['./login-password-phase.component.scss']
})
export class LoginPasswordPhaseComponent implements OnChanges {
  @Input() username = '';
  @Input() password = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Output() phaseChange = new EventEmitter<PhaseChange>();

  passwordForm = new FormControl<string>('');

  showPassword = false;

  constructor() {
    this.passwordForm.setValue(this.password);
    this.passwordForm.setValidators(Validators.required);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['password']?.currentValue) {
      this.passwordForm.setValue(this.password);
      this.passwordForm.setValidators(Validators.required);
    }

    if (changes['loading']) {
      const isLoading = changes['loading']?.currentValue;
      this.passwordForm[isLoading ? 'disable' : 'enable']();
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  handleBackPhase() {
    this.phaseChange.emit({
      formData: {
        password: this.password
      },
      isBack: true,
      next: Phases.Username
    });
  }

  handleSubmit() {
    this.passwordForm.markAllAsTouched();
    this.passwordForm.markAsDirty();

    if (this.passwordForm.invalid) {
      return;
    }

    this.phaseChange.emit({
      formData: {
        password: this.passwordForm.value
      },
      next: Phases.LoginSubmit
    });
  }

  handleForgotPasswordPhase() {
    this.phaseChange.emit({
      formData: {
        password: this.passwordForm.value
      },
      next: Phases.ForgotPassword
    });
  }
}
