
<p-breadcrumb
  styleClass="breadcrumbs"
  [model]="breadcrumbs"
>
  <ng-template pTemplate="item" let-item>
    <a [href]="item.url">
      <span class="breadcrumb-link">
        <typography-p [label]="item.label" p="caption"></typography-p>
      </span>
    </a>
  </ng-template>
  <ng-template pTemplate="separator">
    <span class="material-icons breadcrumb-link">chevron_right</span>
  </ng-template>
</p-breadcrumb>
