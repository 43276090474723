<div *ngIf="state.ready">
  <dashboard-user-idle-dialog
    [currentTime]="currentIdleTime"
    [severity]="idleDialogSeverity"
    [open]="openIdleDialog"
    (logout)="handleLogout()"
    (resetTimer)="handleResetIdleTimer()"
  >
  </dashboard-user-idle-dialog>
  <dashboard-layout
    [logo]="logo"
    [header]="state.header"
    [logoShort]="logoShort"
    [user]="state.user.details"
    (logout)="handleLogout()"
    (search)="handleSearch($event)"
    (accountChange)="handleAccountChange($event)"
    (rightActionButton)="handleActionButton($event)"
    (searchResultSelect)="handleSearchResultSelect($event)"
  >
  </dashboard-layout>
</div>

<div class="skeleton-loader" *ngIf="!state.ready">
  <p-skeleton width="100vw" height="8vh" />
  <p-skeleton width="90vw" height="85vh" [style]="{ margin: '16px' }" />
</div>
