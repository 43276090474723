import { inject, Injectable } from "@angular/core";
import { AuthApi, AuthSSOProviderDto } from "@velocity/common";
import { map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authApi = inject(AuthApi);

  /** used in classic enterprise */
  private getDefaultSSOProvider(providers: AuthSSOProviderDto[]) {
    const defaultSSOProvider = providers.find((provider) => 
      provider.name === 'entra'
    );

    if (!defaultSSOProvider) {
      return providers.find((provider) => provider.name === 'okta');
    }

    return defaultSSOProvider;
  }


  getSSOProviderByEmail(email: string) {
    return this.authApi.getSSOProviderByEmail(email).pipe(
      map((value) => {
        if (value.errors) {
          throw { ...value.errors[0] };
        }

        if (!value.data?.length) {
          return;
        }

        return this.getDefaultSSOProvider(value.data);
      })
    );
  }

  loginWithPassword(email: string, password: string) {
    return this.authApi.authenticateByPassword(email, password).pipe(
      map((value) => {
        if (value.errors) {
          throw { ...value.errors[0] };
        }

        return value;
      })
    );
  }
}
