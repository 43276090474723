<div class="navigation-search-container">
  <span class="material-icons navigation-search-icon">
    search
  </span>
  <p-autoComplete
    styleClass="search"
    [placeholder]="placeholder"
    [formControl]="searchField"
    [suggestions]="results"
    (onSelect)="handleSelect($event)"
    (completeMethod)="handleSearch($event)"
  >
    <ng-template let-item pTemplate="item">
      <div>
        <typography-p
          [label]="item.propertyAccountId + ': ' + item.propertyName"
          p="body2"
        />
        <span class="option-address">
          <typography-p
            [label]="item.addressLine1 + ', ' + item.state"
            p="caption"
          />
        </span>
      </div>
    </ng-template>
  </p-autoComplete>
</div>
