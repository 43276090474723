<div class="dashboard">
  <header class="dashboard-header">
    <dashboard-navigation
      [loading]="header.details.loading"
      [logo]="logo"
      [logoShort]="logoShort"
      [user]="user"
      [menu]="header.menu"
      [links]="header.links"
      [searchResult]="header.searchResult"
      (logout)="handleLogout()"
      (search)="handleSearch($event)"
      (accountChange)="handleAccountChange($event)"
      (searchResultSelect)="handleSearchResultSelect($event)"
    >
    </dashboard-navigation>
  </header>
  <main class="dashboard-main">
    <dashboard-details-area
      [header]="header"
      (rightActionButton)="handleActionButton($event)"
    >
    </dashboard-details-area>
    <div class="dashboard-page-container">
      <router-outlet></router-outlet>
    </div>
  </main>
  <footer class="dashboard-footer">
    <common-footer></common-footer>
  </footer>
</div>
