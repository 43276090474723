
export enum Permissions {
  /** Global permissions */
  APP_PERSISTENT_FILTERS = 'app:persistent-filters',
  APP_DOCUMENT_UPLOAD_LIMIT = 'app:document-upload-limit',

  /** Property permissions */
  PROPERTY_VIEW = 'property',
  PROPERTY_FINANCIALS_VIEW = 'property:property-financials:view',
  PROPERTY_SITE_FINANCIALS_VIEW = 'property:site-financials:view',
  PROPERTY_OVERVIEW_PROPERTY_INFO_VIEW = 'property:overview-property-info:view',
  PROPERTY_OVERVIEW_OWNERSHIP_INFO_VIEW = 'property:overview-ownership-info:view',
  PROPERTY_OVERVIEW_MARKET_INFO_VIEW = 'property:overview-market-info:view',
  PROPERTY_OVERVIEW_NOTES_VIEW = 'property:overview-notes:view',
  PROPERTY_OVERVIEW_NOTES_ADD = 'property:overview-notes:add',
  PROPERTY_OVERVIEW_NOTES_REPLY = 'property:overview-notes:reply',
  PROPERTY_OVERVIEW_ASSIGNMENTS_VIEW = 'property:overview-assignments:view'
}
