import { Component, inject, OnInit } from '@angular/core';
import { CustomTableComponent } from '@velocity/common';
import { PropertiesStore } from './property-list.store';

const sortMode = {
  ASC: 1,
  DESC: -1,
};

@Component({
  selector: 'property-list-page',
  templateUrl: './property-list.component.html',
  styleUrl: './property-list.component.scss',
  standalone: true,
  imports: [CustomTableComponent],
})
export class PropertyListComponent implements OnInit {
  store = inject(PropertiesStore);

  ngOnInit() {
    this.store.loadPropertiesList();
  }

  columns = [
    { field: 'id', header: 'Property ID' },
    { field: 'addressLine1', header: 'Street' },
    { field: 'city', header: 'City' },
    { field: 'state', header: 'State' },
    { field: 'region', header: 'Region' },
  ];

  onPageChange(currentPage: number) {
    this.store.onPageChange(currentPage);
  }
  onCustomSort(event: unknown) {
    // This custom sort only triggers when you pass customSort=true in CUSTOM TABLE
    // Not passing customSort=true in CUSTOM TABLE will use the default sorting behaviour
    const prop = event as { order: number; field: string };
    const order = prop.order === sortMode.ASC ? 'asc' : 'desc';
    this.store.onFilterChange({
      sortKey: [prop.field as string],
      sortOrder: [order],
    });
  }
}