import { inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../provider-tokens";
import { HttpService } from "../http.service";
import { HttpParams } from "@angular/common/http";
import {
  PaginatedResultDto,
  GetPropertyPayloadDto,
  PropertyDto,
  BaseHttpResponseDto,
  BaseHttpErrorDto,
  GetPropertiesPayloadDto,
  PropertiesResponseDto
} from "../../types";
import { PropertyDetailsDto } from "../../types/dto/property-details.dto";

@Injectable({
  providedIn: 'root'
})
export class PropertyApi {
  private http = inject(HttpService);
  private environment = inject(ENVIRONMENT);

  private baseUrl = `${this.environment.apiUrl}/api/property`;

  getProperty(payload: GetPropertyPayloadDto) {
    const params = new HttpParams()
      .set('sortKey', payload.sortKey)
      .set('searchString', payload.searchString)
      .set('page', payload.page ?? '1')
      .set('pageSize', payload.pageSize ?? '15')
      .set('accountId', payload.accountId ?? '0');
    
    return this.http.get<
      PaginatedResultDto<PropertyDto>
    >(this.baseUrl, params);
  }

  getPropertyDetails(propertyAccountId: string, accountId: number) {
    const params = new HttpParams()
      .set('propertyAccountId', propertyAccountId)
      .set('accountId', accountId.toString());

    return this.http.get<
      BaseHttpResponseDto<PropertyDetailsDto, BaseHttpErrorDto>
    >(`${this.baseUrl}/details`, params);
  }

  getPropertiesList(payload: GetPropertiesPayloadDto) {
    const params = {
      ...payload,
      sortKey: JSON.stringify(payload.sortKey),
      sortOrder: JSON.stringify(payload.sortOrder)
    };

    return this.http.post<
    BaseHttpResponseDto<PropertiesResponseDto, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/properties`, params);
  }
}
