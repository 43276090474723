import { Store } from "@ngrx/store";
import { AppState } from "../../types/app-state";
import { inject } from "@angular/core";

import { StorageService } from "../storage";
import { ENVIRONMENT } from "../../provider-tokens";
import { selectAccessToken, selectAccountId, selectCurrentTab, selectCurrentUrl, selectDerivedState, selectPageDetails, selectPermissions } from "./app-state.feature";
import {
  clearUserDetails,
  clearUserPermissions,
  headerFireButtonAction,
  headerPropertySearch,
  headerSetDetails,
  headerSetDetailsLoading,
  headerSetPageLoading,
  headerSetRightActionButtons,
  loadBasicUserDetails,
  onChangeUserAccount,
  setAccessToken,
  userLogout
} from "./app-state.actions";
import { RightActionButtonPayload } from "../../types/right-action-button-payload";
import { HeaderDetails, RightActionButton } from "../../types";

export class AppStateService {
  private store = inject(Store<AppState>);
  private storage = inject(StorageService);
  private environment = inject(ENVIRONMENT);

  loadUser() {
    this.store.dispatch(
      loadBasicUserDetails()
    );
  }
  
  getAccessToken() {
    const persistentAccessToken = this.storage.get(this.environment.tokenName);
    const accessToken = (this.store.selectSignal(selectAccessToken))();

    if (!accessToken && persistentAccessToken) {
      this.setAccessToken(persistentAccessToken);
    }

    return accessToken.value.length ? accessToken.value : persistentAccessToken;
  }

  setAccessToken(value: string) {
    this.storage.set(this.environment.tokenName, value);
    this.store.dispatch(
      setAccessToken({ accessToken: value })
    );
  }

  setHeaderDetails(value: Partial<HeaderDetails>) {
    this.store.dispatch(
      headerSetDetails({ payload: value })
    );
  }

  setRightActionButtons(value: RightActionButton[]) {
    this.store.dispatch(
      headerSetRightActionButtons({ payload: value })
    );
  }

  setHeaderPageLoading(loading: boolean) {
    this.store.dispatch(
      headerSetPageLoading({ loading })
    );
  }

  setHeaderDetailsLoading(loading: boolean) {
    this.store.dispatch(
      headerSetDetailsLoading({ loading })
    );
  }

  changeCurrentAccount(accountId: number) {
    this.store.dispatch(
      onChangeUserAccount({
        accountId
      })
    );
  }

  clearPermissions() {
    this.store.dispatch(clearUserPermissions());
  }

  clearUserDetails() {
    this.store.dispatch(clearUserDetails());
  }

  handleRightButtonAction(payload: Omit<RightActionButtonPayload, 'pageIdentifier'>) {
    const state = this.pageDetails();

    this.store.dispatch(
      headerFireButtonAction({
        payload: {
          buttonIdentifier: payload.buttonIdentifier,
          pageIdentifier: state.pageIdentifier
        }
      })
    );
  }

  handleSearch(searchKey: string) {
    this.store.dispatch(
      headerPropertySearch({ searchKey })
    );
  }

  logout() {
    return this.store.dispatch(userLogout());
  }

  get permissions$() {
    return this.store.select(selectPermissions);
  }

  get accountId$() {
    return this.store.select(selectAccountId);
  }

  get currentPageTab$() {
    return this.store.select(selectCurrentTab);
  }

  get url$() {
    return this.store.select(selectCurrentUrl);
  }

  get state() {
    return this.store.selectSignal(selectDerivedState);
  }

  get pageDetails() {
    return this.store.selectSignal(selectPageDetails);
  }

  get url() {
    return this.store.selectSignal(selectCurrentUrl);
  }
}
