import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PrimeNgFramework } from '@velocity/common';

@Component({
  selector: 'dashboard-navigation-logo',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgFramework
  ],
  templateUrl: './navigation-logo.component.html',
  styleUrls: ['./navigation-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavigationLogoComponent {
  @Input() logoShort!: string;
  @Input() logo!: string;
  @Input() href = '/';
}
