import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import * as AppStateActions from './app-state.actions';
import { initialState } from './initial.state';
import { AppStateDerived } from '../../types';
import { getRouterSelectors } from '@ngrx/router-store';

const {
  selectUrl,
  selectCurrentRoute, // select the current route
} = getRouterSelectors();

export const appStateFeature = createFeature({
  name: 'Velocity',
  reducer: createReducer(
    initialState,

    on(AppStateActions.changePreviousUrl, (state, { previousUrl }) => ({
      ...state,
      previousUrl
    })),

    on(AppStateActions.userLogout, (state) => ({
      ...state,
      user: {
        accessToken: {
          loading: false,
          hasError: false,
          error: null,
          value: ''
        },
        details: {
          loading: false,
          hasError: false,
          error: null,
          value: {}
        },
        permissions: {
          loading: false,
          hasError: false,
          error: null,
          value: []
        }
      }
    })),

    on(AppStateActions.onChangeUserAccount, (state, { accountId }) => {
      const { userAccountDto, associatedAccount } = state.user.details.value;
      const currentAccount = userAccountDto?.find(
        (account) => account.accountId === accountId
      );

      return {
        ...state,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            value: {
              ...state.user.details.value,
              accountId,
              associatedAccount: currentAccount?.accountName ?? associatedAccount
            }
          }
        }
      }
    }),

    on(AppStateActions.loadBasicUserDetails, (state) => ({
      ...state,
      user: {
        ...state.user,
        details: {
          ...state.user.details,
          hasError: false,
          error: null,
          loading: true
        }
      }
    })),

    on(AppStateActions.setAccessToken, (state, { accessToken }) => ({
      ...state,
      user: {
        ...state.user,
        accessToken: {
          ...state.user.accessToken,
          hasError: false,
          loading: false,
          error: null,
          value: accessToken
        }
      }
    })),

    on(AppStateActions.onBasicUserDetailsResolve, (state, { userId, accountId }) => {
      return {
        ...state,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            loading: false,
            hasError: false,
            value: {
              ...state.user.details.value,
              userId,
              accountId
            },
            error: null
          },
          permissions: {
            ...state.user.permissions,
            loading: true,
            hasError: false,
            error: null
          }
        }
      }
    }),

    on(AppStateActions.onBasicUserDetailsFailure, (state, { errorMessage }) => ({
      ...state,
      user: {
        ...state.user,
        accessToken: {
          ...state.user.accessToken,
          loading: false,
          hasError: true,
          error: errorMessage,
          value: ''
        }
      }
    })),

    on(AppStateActions.clearAccessToken, (state) => ({
      ...state,
      user: {
        ...state.user,
        accessToken: {
          loading: false,
          hasError: false,
          error: null,
          value: ''
        }
      }
    })),


    on(AppStateActions.clearUserPermissions, (state) => ({
      ...state,
      user: {
        ...state.user,
        permissions: {
          ...state.user.permissions,
          value: []
        }
      }
    })),

    on(AppStateActions.loadUserDetails, (state) => ({
      ...state,
      user: {
        ...state.user,
        details: {
          ...state.user.details,
          loading: true,
          hasError: false,
          error: null
        }
      }
    })),

    on(AppStateActions.onUserDetailsResolve, ((state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        details: {
          loading: false,
          error: null,
          hasError: false,
          value: {
            ...state.user.details.value,
            ...payload
          }
        }
      }
    }))),

    on(AppStateActions.onUserDetailsFailure, (state, { errorMessage }) => ({
      ...state,
      user: {
        ...state.user,
        details: {
          ...state.user.details,
          loading: false,
          hasError: true,
          error: errorMessage,
          value: {}
        }
      }
    })),

    on(AppStateActions.clearUserDetails, (state) => ({
      ...state,
      header: {
        ...initialState.header,
        visible: false
      },
      user: {
        ...state.user,
        details: {
          loading: false,
          hasError: false,
          error: null,
          value: {}
        },
        permissions: {
          loading: false,
          hasError: false,
          error: null,
          value: []
        }
      }
    })),

    on(AppStateActions.loadUserPermissions, (state) => ({
      ...state,
      user: {
        ...state.user,
        permissions: {
          ...state.user.permissions,
          loading: true,
          hasError: false,
          error: null
        },
        details: {
          ...state.user.details
        }
      }
    })),

    on(AppStateActions.onUserPermissionsResolve, (state, { permissions }) => {

      return {
        ...state,
        user: {
          ...state.user,
          permissions: {
            loading: false,
            error: null,
            hasError: false,
            value: permissions
          }
        }
      };
    }),

    on(AppStateActions.setPermissions, (state, { permissions }) => {
      return {
        ...state,
        user: {
          ...state.user,
          permissions: {
            loading: false,
            error: null,
            hasError: false,
            value: permissions
          }
        }
      };
    }),

    on(AppStateActions.onUserPermissionsFailure, (state, { errorMessage }) => ({
      ...state,
      user: {
        ...state.user,
        permissions: {
          ...state.user.permissions,
          loading: false,
          hasError: true,
          error: errorMessage,
          value: []
        }
      }
    })),

    on(AppStateActions.clearUserPermissions, (state) => ({
      ...state,
      user: {
        ...state.user,
        permissions: {
          loading: false,
          hasError: false,
          error: null,
          value: []
        }
      }
    })),

    on(AppStateActions.headerSetDetails, (state, { payload }) => ({
      ...state,
      header: {
        ...state.header,
        details: {
          ...initialState.header.details,
          ...payload,
        }
      }
    })),

    on(AppStateActions.headerSetPageLoading, (state, { loading }) => ({
      ...state,
      header: {
        ...state.header,
        pageLoading: loading
      }
    })),

    on(AppStateActions.headerSetDetailsLoading, (state, { loading }) => ({
      ...state,
      header: {
        ...state.header,
        details: {
          ...state.header.details,
          loading
        }
      }
    })),

    on(AppStateActions.headerSetNavigation, (state, { payload }) => ({
      ...state,
      header: {
        ...state.header,
        ...payload,
        details: { ...state.header.details }
      }
    })),

    on(AppStateActions.headerPropertySearch, (state) => ({
      ...state,
      header: {
        ...state.header,
        searching: true
      }
    })),

    on(AppStateActions.headerSetRightActionButtons, (state, { payload }) => ({
      ...state,
      header: {
        ...state.header,
        details: {
          ...state.header.details,
          rightActionButtons: payload
        }
      }
    })),

    on(AppStateActions.headerPropertySearchResolve, (state, { payload }) => ({
      ...state,
      header: {
        ...state.header,
        searching: false,
        searchResult: payload.items
      }
    }))
  ),

  extraSelectors: (({
    selectUser,
    selectVelocityState
  }) => ({
    selectCurrentTab: createSelector(selectCurrentRoute, (route) => {
      const tab = route.queryParams.tab;
      
      return tab;
    }),
    selectRole: createSelector(selectUser, (user) => {
      const client = user.details.value.userAccountDto?.find(
        (account) => user.details.value.accountId === account.accountId
      );

      return client?.userRoleDto.roleName;
    }),
    selectPreviousUrl: createSelector(selectVelocityState, (state) => state.previousUrl),
    selectAccountId: createSelector(selectUser, (user) => user.details.value.accountId),
    selectHeader: createSelector(selectVelocityState, (state) => state.header),
    selectAccessToken: createSelector(selectUser, (user) => user.accessToken),
    selectDerivedState: createSelector(selectVelocityState, selectUrl, selectCurrentRoute, (state, url, route): AppStateDerived => {
      const path = url.split('?')[0];
      const tab = route.queryParams.tab;

      return {
        loading: (state.user.accessToken.loading 
          || state.user.details.loading
          || state.user.permissions.loading),
        ready: (!state.user.accessToken.loading 
          && !state.user.details.loading
          && !state.user.permissions.loading) && (
          !state.user.accessToken.hasError
          && !state.user.details.hasError
          && !state.user.permissions.hasError
        ),
        user: {
          accessToken: state.user.accessToken.value,
          details: {
            isLoggedIn: true,
            firstName: `${state.user.details.value.firstName}`,
            lastName: `${state.user.details.value.lastName}`,
            fullName: `${state.user.details.value.fullName}`,
            email: `${state.user.details.value.emailAddress}`,
            userId: `${state.user.details.value.userId}`,
            role: `${state.user.details.value.roleId}`,
            accountId: state.user.details.value.accountId ?? 0,
            permissions: state.user.permissions.value,
            avatar: state.user.details.value.userDocumentsDto?.[0]?.absoluteUri,
            accounts: state.user.details.value.userAccountDto?.map((account) => ({
              id: account.id,
              accountId: account.accountId,
              name: account.accountName,
              displayName: account.displayName,
              role: account.userRoleDto.roleName,
              roleId: account.userRoleDto.id,
              icon: `assets/logos/accounts/${account.accountName}.svg`
            })) ?? [],
            account: state.user.details.value.userAccountDto?.map((account) => ({
              id: account.id,
              accountId: account.accountId,
              name: account.accountName,
              displayName: account.displayName,
              role: account.userRoleDto.roleName,
              roleId: account.userRoleDto.id,
              icon: `assets/logos/accounts/${account.accountName}.svg`
            })).find((account) => account.accountId == state.user.details.value.accountId)
          }
        },
        header: {
          ...state.header,
          details: {
            ...state.header.details,
            loading: state.header.pageLoading || state.header.details.loading,
            activeTab: tab,
            tabs: state.header.details.tabs.map((tabItem) => ({
              label: tabItem.label,
              route: path,
              queryParams: {
                tab: tabItem.value
              }
            }))
          }
        }
      }
    }),
    selectUpdateProfilePayload: createSelector(selectUser, (user) => {
      return {
        ...user.details.value,
        id: user.details.value.id ?? 0,
        userId: `${user.details.value.userId}`,
        associatedAccount: `${user.details.value.associatedAccount}`,
        accountId: 0
      };
    }),
    selectPageDetails: createSelector(selectVelocityState, selectUrl, selectCurrentRoute, (state, url, route) => {
      const path = url.split('?')[0];
      const tab = route.queryParams.tab;

      return {
        ...state.header.details,
        activeTab: tab,
        tabs: state.header.details.tabs.map((tab) => ({
          label: tab.label,
          route: path,
          queryParams: {
            tab: tab.value
          }
        }))
      };
    }),
    selectRoute: createSelector(
      selectCurrentRoute,
      (route) => (route)
    ),
    selectCurrentUrl: createSelector(selectUrl, (url) => url),
    selectPermissions: createSelector(selectUser, (user) => user.permissions.value)
  }))
});

export const {
  name,
  reducer,
  selectRole,
  selectPreviousUrl,
  selectRoute,
  selectCurrentTab,
  selectAccountId,
  selectCurrentUrl,
  selectPageDetails,
  selectPermissions,
  selectUser,
  selectHeader,
  selectAccessToken,
  selectVelocityState,
  selectDerivedState,
  selectUpdateProfilePayload
} = appStateFeature;
