import { createAction, props } from '@ngrx/store';
import { AppStateActionList } from './app-state-actions.enum';
import {
  Header,
  HeaderDetails,
  PaginatedResultDto,
  PropertyDto,
  RightActionButton,
  UserProfileDto
} from '../../types';
import { RightActionButtonPayload } from '../../types/right-action-button-payload';
import { Nullable } from 'primeng/ts-helpers';

export const onChangeUserAccount = createAction(
  AppStateActionList.user_onChangeAccount,
  props<{ accountId: number }>()
);

export const onBasicUserDetailsResolve = createAction(
  AppStateActionList.user_onBasicUserDetailsResolve,
  props<{
    userId: string,
    accountId: number
  }>()
);

export const onBasicUserDetailsFailure = createAction(
  AppStateActionList.user_onBasicUserDetailsFailure,
  props<{errorMessage: string}>()
)

export const setAccessToken = createAction(
  AppStateActionList.user_setAccessToken,
  props<{accessToken: string}>()
);

export const loadBasicUserDetails = createAction(
  AppStateActionList.user_loadBasicUserDetails
);

export const clearAccessToken = createAction(
  AppStateActionList.user_clearAccessToken
);

export const loadUserDetails = createAction(
  AppStateActionList.user_loadDetails,
  props<{
    userId: string,
    accountId: string
  }>()
);

export const onUserDetailsResolve = createAction(
  AppStateActionList.user_onDetailsResolve,
  props<{payload: Partial<UserProfileDto>}>()
);

export const onUserDetailsFailure = createAction(
  AppStateActionList.user_onDetailsFailure,
  props<{errorMessage: string}>()
);

export const clearUserDetails = createAction(
  AppStateActionList.user_clearDetails
);

export const onUserPermissionsResolve = createAction(
  AppStateActionList.user_onPermissionsResolve,
  props<{permissions: string[]}>()
);

export const onUserPermissionsFailure = createAction(
  AppStateActionList.user_onPermissionsFailure,
  props<{errorMessage: string}>()
)

export const loadUserPermissions = createAction(
  AppStateActionList.user_loadPermissions
);

export const clearUserPermissions = createAction(
  AppStateActionList.user_clearPermissions
);

export const userLogout = createAction(
  AppStateActionList.user_logout
);

export const headerSetDetails = createAction(
  AppStateActionList.header_setDetails,
  props<{ payload: Partial<HeaderDetails> }>()
);

export const headerSetNavigation = createAction(
  AppStateActionList.header_setNavigation,
  props<{ payload: Partial<Header> }>()
);

// TODO: finalize payload type
export const headerFireButtonAction = createAction(
  AppStateActionList.header_fireButtonAction,
  props<{ payload: RightActionButtonPayload }>()
);

export const headerPropertySearch = createAction(
  AppStateActionList.header_propertySearch,
  props<{ searchKey: string }>()
);

export const headerPropertySearchResolve = createAction(
  AppStateActionList.header_propertySearchResolve,
  props<{ payload: PaginatedResultDto<PropertyDto> }>()
);

export const headerPropertySearchFailure = createAction(
  AppStateActionList.header_propertySearchFailure,
  props<{ errorMessage: string }>()
);

export const headerSetRightActionButtons = createAction(
  AppStateActionList.header_setRightActionButtons,
  props<{ payload: RightActionButton[] }>()
);

export const headerSetPageLoading = createAction(
  AppStateActionList.header_setPageLoading,
  props<{ loading: boolean }>()
);

export const headerSetDetailsLoading = createAction(
  AppStateActionList.header_setDetailsLoading,
  props<{ loading: boolean }>()
);

export const setPermissions = createAction(
  AppStateActionList.user_setPermissions,
  props<{ permissions: string[] }>()
);

export const changePreviousUrl = createAction(
  AppStateActionList.app_changePreviousUrl,
  props<{
    previousUrl: Nullable<string>
  }>()
);
