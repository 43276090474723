<p-panel [toggleable]="true">
  <ng-template pTemplate="header">
    <div class="collapsible-card-header">
      <div class="collapsible-card-header-details">
        <span class="material-icons collapsible-card-header-icon" *ngIf="icon">
          {{icon}}
        </span>
        <typography-p
          class="collapsible-card-title"
          [label]="title"
          p="caption"
          [bold]="true"
        >
        </typography-p>
      </div>
      <ng-content select="[slot=header-action]"></ng-content>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-content select="[slot=content]"></ng-content>
  </ng-template>
</p-panel>
