import { provideComponentStore } from "@ngrx/component-store";
import { PropertyDetailsComponent } from "./details/property-details.component";
import { PropertyDetailsStore } from "./details/property-details.store";
import { PropertyListComponent } from "./list/property-list.component";

export const propertyRoutes = [
  {
    path: 'properties/details/:propertyAccountId',
    component: PropertyDetailsComponent,
    providers: [
      provideComponentStore(PropertyDetailsStore)
    ]
  },
  {
    path: 'properties',
    component: PropertyListComponent,
    providers: [
      // provideComponentStore(PropertyDetailsStore)
    ]
  }
];
