import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PrimeNgFramework } from '../../modules/primeng-framework';
import { DropdownOption } from '../../types';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { TypographyPComponent } from '../typography-p/typography-p.component';

@Component({
  selector: 'searchable-dropdown',
  standalone: true,
  styleUrl: './searchable-dropdown.component.scss',
  templateUrl: './searchable-dropdown.component.html',
  imports: [
    CommonModule,
    PrimeNgFramework,
    ReactiveFormsModule,
    TypographyPComponent,
    FormsModule
  ],
  encapsulation: ViewEncapsulation.None
})
export class SearchableDropdownComponent implements OnInit, OnChanges {
  @Input() selectedValuesAsChip = false;
  @Input() items: DropdownOption[] = [];
  @Input() selectedItems?: DropdownOption[];
  @Input() loading = false;
  @Input() optionLabel = 'label';
  @Input() placeholder = 'Select';
  @Output() optionSelect = new EventEmitter<DropdownOption[]>();

  selectedValues = new FormControl<DropdownOption[]>([]);

  ngOnInit(): void {
    if (this.selectedItems) {
      this.selectedValues.patchValue([...this.selectedItems]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItems']) {
      this.selectedValues.patchValue([
        ...changes['selectedItems'].currentValue
      ]);
    }
  }

  handleOptionSelect(event: MultiSelectChangeEvent) {
    this.optionSelect.emit(event.value);
  }
}
