export enum AppStateActionList {
  app_changePreviousUrl = 'app.changePreviousUrl',

  user_onChangeAccount = 'user.onChangeAccount',
  user_setAccessToken = 'user.setAccessToken',
  user_loadBasicUserDetails = 'user.loadBasicUserDetails',
  user_onBasicUserDetailsResolve = 'user.onBasicUserDetailsResolve',
  user_onBasicUserDetailsFailure = 'user.onBasicUserDetailsFailure',
  user_clearAccessToken = 'user.clearAccessToken',
  user_loadDetails = 'user.loadDetails',
  user_onDetailsResolve = 'user.onDetailsResolve',
  user_onDetailsFailure = 'user.onDetailsFailure',
  user_clearDetails = 'user.clearDetails',
  user_setPermissions = 'user.setPermissions',
  user_loadPermissions = 'user.loadPermissions',
  user_onPermissionsResolve = 'user.onPermissionsResolve',
  user_onPermissionsFailure = 'user.onPermissionsFailure',
  user_clearPermissions = 'user.clearPermissions',
  user_logout = 'user.logout',

  header_setPageLoading = 'header.setPageLoading',
  header_setDetails = 'header.setDetails',
  header_setDetailsLoading = 'header.setDetailsLoading',
  header_setMenu = 'header.setMenu',
  header_setNavigation = 'header.setNavigation',
  header_setRightActionButtons = 'header.setRightActionButtons',
  header_fireButtonAction = 'header.fireButtonAction',
  header_propertySearch = 'header.propertySearch',
  header_propertySearchResolve = 'header.propertySearchResolve',
  header_propertySearchFailure = 'header.propertySearchFailure',
  
}
