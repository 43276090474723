import { Component, Input, ViewEncapsulation } from "@angular/core";
import { PrimeNgFramework, TypographyPComponent } from "@velocity/common";
import { MenuItem } from "primeng/api";

@Component({
  selector: 'dashboard-breadcrumbs',
  styleUrl: './breadcrumbs.component.scss',
  templateUrl: './breadcrumbs.component.html',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardBreadcrumbsComponent {
  @Input() breadcrumbs!: MenuItem[];
}
