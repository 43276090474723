import { Component, Input, ViewEncapsulation } from "@angular/core";
import {
  PrimeNgFramework,
  TypographyPComponent,
  TypographyHeaderComponent,
  HeaderDetailsAttribute
} from "@velocity/common";

@Component({
  selector: 'dashboard-details-area-attributes',
  styleUrl: './details-area-attributes.component.scss',
  templateUrl: './details-area-attributes.component.html',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyHeaderComponent,
    TypographyPComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDetailsAreaAttributesComponent {
  @Input() attributes!: HeaderDetailsAttribute[];

  getInitials(name: string) {
    const initials = name.split(' ').map((value) => value.charAt(0));

    return `${initials[0]}${initials[initials.length -1]}`;
  }

  castArray(value: string | string[]) {
    return value as string[];
  }
}
