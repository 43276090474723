import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appRoutes } from './app.routes';
import { ConfirmationService } from 'primeng/api';
import { NgxPermissionsModule } from 'ngx-permissions';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../../environments/environment';

import {
  StorageService,
  LocalStorageService,
  ENVIRONMENT,
  provideAppState,
  HttpTokenInterceptor
} from '@velocity/common';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: StorageService,
      useClass: LocalStorageService
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },

    ConfirmationService,

    provideFirebaseApp(() => initializeApp(environment.configuration.firebase)),
    provideAuth(() => getAuth()),
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes, withPreloading(PreloadAllModules)),
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    provideAnimations(),
    provideAppState(environment)
  ],
};
