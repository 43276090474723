import { inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../provider-tokens";
import { HttpService } from "../http.service";
import { HttpParams } from "@angular/common/http";
import {
  AuthResetPasswordPayloadDto,
  BaseHttpErrorDto,
  BaseHttpResponseDto,
  AuthSSOProviderDto,
  AuthPasswordLoginDto
} from "../../types";

@Injectable({
  providedIn: 'root'
})
export class AuthApi {
  private http = inject(HttpService);
  private environment = inject(ENVIRONMENT);

  private baseUrl = `${this.environment.apiUrl}/api/auth`;

  getSSOProviderByEmail(email: string) {
    const params = new HttpParams().set('email', email);

    return this.http.get<
      BaseHttpResponseDto<AuthSSOProviderDto[], BaseHttpErrorDto[]>
    >(`${this.baseUrl}/provider`, params);
  }

  authenticateByPassword(email: string, password: string) {
    const body = {
      username: email,
      password
    };

    return this.http.post<
      BaseHttpResponseDto<AuthPasswordLoginDto, BaseHttpErrorDto[]>
    >(this.baseUrl, body);
  }

  getPermissionsToken(userId: string, accountId: string) {
    const params = new HttpParams()
      .set('id', userId)
      .set('accountId', accountId);

    return this.http.get<
      BaseHttpResponseDto<string, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/token`, params);
  }


  resetPassword(payload: AuthResetPasswordPayloadDto) {
    return this.http.post<
      BaseHttpResponseDto<unknown, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/password`, payload);
  }

  logout() {
    return this.http.get<
      BaseHttpResponseDto<unknown, BaseHttpErrorDto[]>
    >(`${this.baseUrl}/signout`);
  }
}
