import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  EncloseNegativePipe,
  PrimeNgFramework,
  TypographyPComponent
} from '@velocity/common';
import { FinancialDataGroup, FinancialGroupTotal } from '../../types';
import { sumBy, isEqual } from 'lodash';

@Component({
  selector: 'financials-table',
  standalone: true,
  imports: [
    PrimeNgFramework,
    CommonModule,
    TypographyPComponent,
    EncloseNegativePipe,
  ],
  providers: [
  ],
  templateUrl: './financials-table.component.html',
  styleUrls: ['./financials-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinancialsTableComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() items: FinancialDataGroup[] = [];
  @Input() totalByGroup: FinancialGroupTotal[] = [];

  tableData: FinancialDataGroup[] = [];
  tableHeaders = [
    {
      label: "Underwritten Budget",
      isVisible: true
    },
    {
      label: "Original Budget",
      isVisible: true
    },
    {
      label: "Revised Budget",
      isVisible: true
    },
    {
      label: "Reporting Adjustment",
      isVisible: true
    },
    {
      label: "Projected Final Cost",
      isVisible: true
    },
    {
      label: "Revised Contract",
      isVisible: true
    },
    {
      label: "Spend To Date",
      isVisible: true
    },
    {
      label: "Cost to Complete",
      isVisible: true
    }
  ];

  ngOnInit(): void {
    this.tableData = [...this.items];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] && 
      !isEqual(changes['items'].currentValue, changes['items'].previousValue)
    ) {
      this.tableData = [...this.items];
    }
  }

  getTotalOf(groupId: number, fieldName: keyof Omit<FinancialGroupTotal, 'groupId'>) {
    const row = this.totalByGroup.find((item) => item.groupId === groupId);

    return row?.[fieldName] ?? '0';
  }

  getMergedTotalOf(fieldName: keyof Omit<FinancialGroupTotal, 'groupId'>) {
    const mergedTotal = sumBy(this.totalByGroup, (value) => value[fieldName]);

    return mergedTotal;
  }

  toggleExpansion(item: FinancialDataGroup) {
    const selected = this.tableData.find((i) => i.label === item.label);

    if (!selected) {
      return;
    }

    const index = this.tableData.indexOf(selected);

    this.tableData[index] = {
      ...selected,
      expanded: !selected.expanded
    };
  }

  get expandedAll() {
    return this.tableData.every((item) => item.expanded);
  }

  toggleAllExpansion() {
    if (this.expandedAll) {
      this.tableData = this.tableData.map((item) => ({
        ...item,
        expanded: false
      }));
      return;
    }

    this.tableData = this.tableData.map((item) => ({
      ...item,
      expanded: true
    }));
  }
}
