import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PrimeNgFramework, TypographyPComponent } from '@velocity/common';

@Component({
  selector: 'property-assignment-user-role-card',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent
  ],
  templateUrl: './assignment-user-role-card.component.html',
  styleUrls: ['./assignment-user-role-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropertyAssignmentUserRoleCardComponent {
  @Input() email!: string;
  @Input() name!: string;
  @Input() role!: string;


  getInitials(name: string) {
    const initials = name.split(' ').map((value) => value.charAt(0));

    return `${initials[0]}${initials[initials.length -1]}`;
  }

  get initials() {
    return this.getInitials(this.name);
  }
}
