
<p-panel styleClass="assignment-user-role-panel">
  <div class="assignment-user-role-container">
    <p-avatar 
      [label]="initials"
      shape="circle"
    />
    <div class="assignment-user-role-name">
      <typography-p
        [label]="name"
        p="caption"
      >
      </typography-p>
      <typography-p
        [label]="email"
        p="caption"
        class="assignment-user-role-email"
        tooltipStyleClass="assignment-user-role-email-tooltip"
        [pTooltip]="email"
        tooltipPosition="bottom" 
      >
      </typography-p>
    </div>
      <typography-p
        [label]="role"
        textAlign="right"
        p="caption"
      >
      </typography-p>
  </div>
</p-panel>
