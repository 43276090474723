
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enclose_negative',
  standalone: true
})
export class EncloseNegativePipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    const val = value ? value : '';
    if (val.includes('-')) {
      return `(${val.substring(1)})`;
    }
    return val;
  }
}
