
<p-dropdown
  [(ngModel)]="selectedAccount"
  optionLabel="name" 
  placeholder="Select a client"
  styleClass="dropdown"
  [options]="accounts"
  optionLabel="displayName"
  (onChange)="handleAccountChange($event)"
>
  <ng-template pTemplate="selectedItem">
    <div class="dropdown-item" *ngIf="selectedAccount">
      <img class="item-icon" [src]="selectedAccount.icon" [alt]="selectedAccount.name">
      <typography-p p="caption" [label]="selectedAccount.displayName"></typography-p>
    </div>
  </ng-template>
  <ng-template let-account pTemplate="item">
    <div class="dropdown-item">
      <img class="item-icon" [src]="account.icon" [alt]="account.name">
      <typography-p p="caption" [label]="account.displayName"></typography-p>
    </div>
  </ng-template>
</p-dropdown>
