
<div class="property-details">
  <ng-container [ngSwitch]="store.activeTab">
    <ng-container *ngSwitchCase="tabs.PROPERTY_OVERVIEW">
      <property-details-overview-tab>
      </property-details-overview-tab>
    </ng-container>

    <ng-container *ngSwitchCase="tabs.PROPERTY_FINANCIALS">
      <property-details-financials-tab>
      </property-details-financials-tab>
    </ng-container>

    <ng-container *ngSwitchCase="tabs.SITE_FINANCIALS">
      <property-details-site-financials-tab>
      </property-details-site-financials-tab>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <property-details-overview-tab>
      </property-details-overview-tab>
    </ng-container>
  </ng-container>
</div>
