import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from "@angular/core";
import {
  PrimeNgFramework,
  TypographyPComponent
} from "@velocity/common";
import { MenuItem } from "primeng/api";

@Component({
  selector: 'dashboard-details-area-tab-menu',
  styleUrl: './details-area-tab-menu.component.scss',
  templateUrl: './details-area-tab-menu.component.html',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDetailsAreaTabMenuComponent implements OnInit, OnChanges {
  @Input() activeTab?: string;
  @Input() items: MenuItem[] = [];

  active?: MenuItem;
  
  ngOnInit(): void {
    const currentTab = this.items.find((item) => item?.queryParams?.['tab'] === this.activeTab);
    this.active = currentTab ? currentTab : this.items[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeTab'] || changes['items']) {
      const currentTab = this.items.find((item) => item?.queryParams?.['tab'] === this.activeTab);
      this.active = currentTab ? currentTab : this.items[0];
    }
  }
}
