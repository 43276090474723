
<div #navigationMenuAnchor>
  <p-menu
    #dashboardNavigationMenu
    [appendTo]="navigationMenuAnchor"
    [popup]="true"
    [model]="menu"
    styleClass="navigation-menu"
  >
    <ng-template pTemplate="item" let-item>
      <a pRipple class="menu-item-link">
        <span class="material-icons icon-blue">{{item.icon}}</span>
        <typography-p [label]="item.label" p="body2"></typography-p>
      </a>
    </ng-template>
  </p-menu>
  <p-button
    [rounded]="true"
    [text]="true"
    (onClick)="dashboardNavigationMenu.toggle($event)"
  >
    <ng-template pTemplate="icon">
      <span class="material-icons mini-icons">apps</span>
    </ng-template>
  </p-button>
</div>
