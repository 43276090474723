import { Component, inject } from '@angular/core';
import {
  PrimeNgFramework,
  DropdownOption
} from '@velocity/common';
import { PropertyDetailsStore } from '../../property-details.store';
import {
  PropertyDetailsSiteFinancialsLayoutComponent
} from '@velocity/property';

@Component({
  selector: 'property-details-site-financials-tab',
  standalone: true,
  imports: [
    PrimeNgFramework,
    PropertyDetailsSiteFinancialsLayoutComponent
],
  providers: [
  ],
  templateUrl: './site-financials.component.html',
  styleUrls: ['./site-financials.component.scss']
})
export class PropertyDetailsSiteFinancialsTabComponent {
  store = inject(PropertyDetailsStore);

  handleOptionUpdate(event: DropdownOption[]) {
    this.store.updateSelectedSiteFinancialFilter(event);
  }
}
