import { Route } from '@angular/router';
import { LoginPageComponent } from './features/login/login.component';
import { LoginStore } from './features/login/login.store';
import { provideComponentStore } from '@ngrx/component-store';
import { DashboardComponent } from '@velocity/dashboard';
import { AuthGuard } from './guards/auth.guard';
import { propertyRoutes } from './features/property/property.routes';

export const appRoutes: Route[] = [
  {
    path: 'login',
    component: LoginPageComponent,
    providers: [ 
      provideComponentStore(LoginStore)
    ]
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      ...propertyRoutes
    ]
  }
];
