import { PropertyLandAcrReportResponseDto } from "@velocity/common";
import { flatMap, sum } from "lodash";
import { FinancialDataGroup, FinancialGroupTotal } from "../types";

export const formatSiteFinancialReportData = (data: PropertyLandAcrReportResponseDto[]): {
  value: FinancialDataGroup[],
  total: FinancialGroupTotal[]
} => {
  const value = flatMap(data.map((item) => 
    item.data.map((acrReport) => ({
      groupId: getGroupId(item.label),
      root: item.label,
      label: acrReport.label,
      expanded: false,
      totalUnderwrittenBudget: sum(acrReport.data.map((acrData) => acrData.velocityUWBudget)),
      totalOriginalBudget: sum(acrReport.data.map((acrData) => acrData.uwBudget)),
      totalRevisedBudget: sum(acrReport.data.map((acrData) => acrData.revisedBudget)),
      totalReportingAdjustment: sum(acrReport.data.map((acrData) => acrData.reportingAdj)),
      totalProjectedFinalCostBudget: sum(acrReport.data.map((acrData) => acrData.prjFinalCostBudget)),
      totalRevisedContract: sum(acrReport.data.map((acrData) => acrData.revisedContract)),
      totalSpendToDate: sum(acrReport.data.map((acrData) => acrData.invoiced)),
      totalCostToComplete: sum(acrReport.data.map((acrData) => acrData.costToComplete)),
      value: acrReport.data.filter((acrData) => acrData.velocityUWBudget > 0).map((acrData) => ({
        code: acrData.categoryCode,
        desc: acrData.categoryDesc,
        rootLabel: acrData.l3Desc,
        parentLabel: acrData.l2Desc,
        underwrittenBudget: acrData.velocityUWBudget,
        originalBudget: acrData.uwBudget,
        revisedBudget: acrData.revisedBudget,
        projectedFinalCostBudget: acrData.prjFinalCostBudget,
        revisedContract: acrData.revisedContract,
        reportingAdjustment: acrData.reportingAdj,
        spendToDate: acrData.invoiced,
        costToComplete: acrData.costToComplete
      }))
    }))
  ));

  return {
    total: data.map((item) => ({
      groupId: getGroupId(item.label),
      totalUnderwrittenBudget: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.velocityUWBudget)
      ))),
      totalOriginalBudget: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.uwBudget)
      ))),
      totalRevisedBudget: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.revisedBudget)
      ))),
      totalReportingAdjustment: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.reportingAdj)
      ))),
      totalProjectedFinalCostBudget: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.prjFinalCostBudget)
      ))),
      totalRevisedContract: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.revisedContract)
      ))),
      totalSpendToDate: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.invoiced)
      ))),
      totalCostToComplete: sum(flatMap(item.data.map((acrReport) =>
        acrReport.data.map((acrData) => acrData.costToComplete)
      )))
    })),
    value
  };
};

const getGroupId = (name: string) => {
  if (name === 'Land') {
    return 1;
  }

  if (name === 'Building Costs') {
    return 2;
  }

  if (name === 'Lease Costs') {
    return 3;
  }

  return 999;
}
