import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
  CollapsibleCardComponent,
  MediaCarouselItem,
  MediaCarouselComponent,
  PrimeNgFramework,
  TypographyPComponent
} from '@velocity/common';
import { PropertyAssignmentUserRoleCardComponent } from '../assignment-user-role-card/assignment-user-role-card.component';
import { isEqual } from 'lodash';

@Component({
  selector: 'property-gallery',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent,
    CollapsibleCardComponent,
    PropertyAssignmentUserRoleCardComponent,
    MediaCarouselComponent
],
  templateUrl: './property-gallery.component.html',
  styleUrls: ['./property-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropertyGalleryComponent implements OnChanges {
  @Input()
  media: MediaCarouselItem[] = [];

  data = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['media']) {
      const hasNoChange = isEqual(
        changes['media'].previousValue,
        changes['media'].currentValue
      );
      
      if (!hasNoChange) {
        this.data = changes['media'].currentValue;
      }
    }
  }
}
