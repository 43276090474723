
<p-panel styleClass="assignment-user-role-panel-list">
  <div class="assignment-user-role-list-container">
    <property-assignment-user-role-card
      *ngFor="let user of assignedUsers"
      [name]="user.name"
      [email]="user.email"
      [role]="user.role"
    >
    </property-assignment-user-role-card>
  </div>
</p-panel>
