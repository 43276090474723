import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PrimeNgFramework, StoreAssignmentDto, TypographyPComponent } from '@velocity/common';
import { PropertyAssignmentUserRoleCardComponent } from '../assignment-user-role-card/assignment-user-role-card.component';

@Component({
  selector: 'property-assignment-user-role-card-list',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent,
    PropertyAssignmentUserRoleCardComponent
  ],
  templateUrl: './assignment-user-role-card-list.component.html',
  styleUrls: ['./assignment-user-role-card-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropertyAssignmentUserRoleCardListComponent {
  @Input() assignedUsers: StoreAssignmentDto[] = [];
}
