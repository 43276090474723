export const environment = {
  production: false,
  appName: 'Velocity',
  appUrl: 'https://dev.velocity.properties',
  apiUrl: 'https://velocityapi.azurewebsites.net',
  apimUrl: 'https://velocitymobileapi.azurewebsites.net',
  tokenName: 'vc-token',
  configuration: {
    autologout: {
      idle: 1800,
      timeout: 30,
      ping: 3600
    },
    firebase: {
      apiKey: 'AIzaSyAsBIGqL5Fp3ccMoPdrgPwGhyxwF7Fs8_U',
      authDomain: 'pit-dev.firebaseapp.com',
      projectId: 'pit-dev',
      storageBucket: 'pit-dev.appspot.com',
      messagingSenderId: '319883517645',
      appId: '1:319883517645:web:873f7628d68cc4fc935384',
      measurementId: 'G-X05YN3PSE3'
    }
  }
  // production: true,
  // appName: 'Velocity',
  // appUrl: 'https://dev.velocity.properties',
  // apiUrl: 'https://velocityapi.azurewebsites.net',
  // apiAuthUrl: 'https://velocityapi.azurewebsites.net',
  // apimUrl: 'https://velocitymobileapi.azurewebsites.net',
  // tokenName: 'vc-token',
  // keys:{
  //   google: 'AIzaSyDyYgtb4ili4Eg6hgCAi0mUU8qNvbUz7Uw',
  //   azureMaps: '4iinJwu_8CH2WKu88xemE-Fw9HaEtJrcN-4GHRHrC8c'
  // },
  // settings:{
  //   autologout: true
  // },
  // config:{
  //   autologout:{
  //     idleTime: 1800,
  //     timeout: 30,
  //     ping: 3600
  //   }
  // },
  // // eslint-disable-next-line
  // // appVersion: require('../../package.json').version,  
  // apiSpotlightUrl:'https://apps.jll.com/SpotlightServiceWAG_UAT/api',     
  // apiWalgreensMilestoneUrl: 'https://walgreensmilestonesdev.azurewebsites.net/api',
  // apiWalgreensMilestoneHeaderCode: '0sgBN6TDjq51kNlSwPOkVQaRrXTZV3c_woDkgEBqBkXHAzFuFCZbug==',
  // apiWalgreensMilestoneDetailsCode: 'gYiAEWmQiU75NZjYQsLHN5P5YyQ-_zNv3tfTaHKvGTVXAzFuBaLoJg==',
  // publicListingUrl:'https://calm-pebble-09e321b10.2.azurestaticapps.net',
  // versionChecker: false,
  // firebaseUserEmail: 'bermwood.technologies@gmail.com',
  // firebaseUserPassword: 'W6f4G2h&@7wu',
  // firebaseConfig: {
  //   apiKey: 'AIzaSyAsBIGqL5Fp3ccMoPdrgPwGhyxwF7Fs8_U',
  //   authDomain: 'pit-dev.firebaseapp.com',
  //   projectId: 'pit-dev',
  //   storageBucket: 'pit-dev.appspot.com',
  //   messagingSenderId: '319883517645',
  //   appId: '1:319883517645:web:873f7628d68cc4fc935384',
  //   measurementId: 'G-X05YN3PSE3'
  // },
  // azureMapsURL: 'https://atlas.microsoft.com/search/address/json',
  // templatefiles:{
  //   propertyCreateBulk: 'https://stpblinklogistics.blob.core.windows.net/upload-property-template/BulkPropertyUpload.xlsx?sp=r&st=2024-05-21T05:47:26Z&se=2027-01-30T13:47:26Z&spr=https&sv=2022-11-02&sr=b&sig=J0SUp4nCJl%2fr%2ftGmlElurqx2jy8wwu0J%2fpxrhkvm%2fyM%3D',
  //   propertyUnitCreateBulk: 'https://stpblinklogistics.blob.core.windows.net/upload-property-template/BulkUnitUpload.xlsx?sp=r&st=2024-05-21T05:48:53Z&se=2027-01-30T13:48:53Z&spr=https&sv=2022-11-02&sr=b&sig=GMEL7m7C56fgDHdWRuuwkSOpwq93Lb0MsbkLaxJkbT8%3D'
  // },
  // bulkUploader: {
  //   apiBaseUrl: 'https://fa-wgrn-bulk-uploader.azurewebsites.net/api/fileValidationReAssignment',
  //   code: 'aNImkLTyiLMqf98HVtHt8YEv-n-PR9sK3G4v_KOPI-4NAzFuoFQy4Q%3D%3D',
  //   apiBaseUrlRows: 'https://fa-wgrn-bulk-uploader.azurewebsites.net/api/bulkreassignmentfile',
  //   codeRows: 'WOTZjV7m28C8ITebyJfK6cmFeDsGKded6MWYbAQk__i5AzFuVf3bTg%3D%3D',
  //   apiBaseUrlUpdateRows: 'https://fa-wgrn-bulk-uploader.azurewebsites.net/api/bulkreassignment',
  //   codeUpdateRows: 'm0jBS9E2bQqwpdpcZtSY7bNUbTtsrUXcWLCOJIDzMQeuAzFuX6bJBg%3D%3D',
  //   apiBaseUrlTemplate: 'https://fa-wgrn-bulk-uploader.azurewebsites.net/api/filetemplate',
  //   codeTemplate: 'I_9Kvi6laLw8V90MVAhF-921AtugbxUt1YfH4BAZyFaUAzFuFBesHw%3D%3D',
  // },
  // wagOptions: {
  //   apiBaseUrl: 'https://fa-wagoptions-d.azurewebsites.net/api/bulkEmail',
  //   code: 'z7lweUO9hQqvKYYzpoW8wYL2o824n2gxQ7bOeOxAH3BGAzFu8eL54w=='
  // },
  // tririga: {
  //   apiBaseUrl: 'https://fa-wagoptions-d.azurewebsites.net/api/fileValidation',
  //   apiTerminatedDatesBaseUrl: 'https://fa-wagoptions-d.azurewebsites.net/api/terminatedDateFileValidation',
  //   code: 'ihMNcikX7MuVNso8M-AL0GcRZxivcQmyhTnItwadqARdAzFuepQvPA==',
  //   terminatedDatesCode: '1P4hdS8BD2y_o6VLfe2ibK3fabc8XXd79oJhrjKxXuoAAzFuFn9Iig=='
  // }
};
