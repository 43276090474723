export const ownershipInfoKeys = {
  acquisitionPeriod: 'Acquisition Period',
  acquisitionPortfolio: 'Acquisition Portfolio',
  dealName: 'Deal Name',
  priorDevelopmentName: 'Development Name',
  jointVenture: 'Joint Venture',
  jointVentureName: 'Joint Venture Name',
  loanPool: 'Loan Pool',
  accountingOwnership: 'Accoiunting Ownership',
  brep8Predecessor: 'BREP 8 Predecessor',
  ownerEntity: 'Owner Entity',
  fund: 'Fund',
  propertyOwnershipType: 'Property Ownership Type',
  taxEntities: 'Tax Entities',
  dispositionPeriod: 'Disposition Period'
};
