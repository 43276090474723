<div class="container">
  <p-floatLabel>
    <input
      type="email"
      name="email"
      pInputText
      class="full-width"
      required
      [formControl]="usernameForm"
    />
    <label for="email">Email</label>
  </p-floatLabel>
  <small
    class="p-error block"
    *ngIf="usernameForm.invalid && usernameForm.dirty && usernameForm.hasError('required')"
  >
    Email is required
  </small>
  <small
    class="p-error block"
    *ngIf="usernameForm.invalid && usernameForm.dirty && usernameForm.hasError('email')"
  >
    Email should be a valid email address
  </small>
  <button
    pButton
    label="Next"
    [raised]="true"
    (click)="handleNextPhase()" 
    [loading]="loading"
    [disabled]="disabled"
  >
  </button>
</div>
