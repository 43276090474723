import { CommonModule } from '@angular/common';
import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { DashboardNavigationLogoComponent } from '../navigation-logo/navigation-logo.component';
import { Account, PrimeNgFramework, TypographyPComponent } from '@velocity/common';
import { EventEmitter } from '@angular/core';
import { DashboardNavigationSearchComponent } from '../navigation-search/navigation-search.component';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'dashboard-navigation-account',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgFramework,
    DashboardNavigationLogoComponent,
    DashboardNavigationSearchComponent,
    TypographyPComponent,
  ],
  templateUrl: './navigation-account.component.html',
  styleUrls: ['./navigation-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavigationAccountComponent {
  @Input() accounts: Account[] = [];
  @Input() selectedAccount!: Account;
  @Output() accountChange: EventEmitter<Account> = new EventEmitter();

  handleAccountChange(event: DropdownChangeEvent) {
    this.accountChange.emit(event.value);
  }
}
