import { CommonModule } from '@angular/common';
import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { DashboardNavigationLogoComponent } from '../navigation-logo/navigation-logo.component';
import { MenuItem } from 'primeng/api';
import { Account, PrimeNgFramework, PropertyDto, UserInfo } from '@velocity/common';
import { EventEmitter } from '@angular/core';
import { DashboardNavigationSearchComponent } from '../navigation-search/navigation-search.component';
import { DashboardNavigationAccountComponent } from '../navigation-account/navigation-account.component';
import { DashboardNavigationProfileComponent } from '../navigation-profile/navigation-profile.component';
import { DashboardNavigationMenuComponent } from '../navigation-menu/navigation-menu.component';

@Component({
  selector: 'dashboard-navigation',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgFramework,
    DashboardNavigationProfileComponent,
    DashboardNavigationLogoComponent,
    DashboardNavigationSearchComponent,
    DashboardNavigationMenuComponent,
    DashboardNavigationAccountComponent
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavigationComponent {
  @Input() loading = true;
  @Input() user!: UserInfo;
  @Input() searchResult: PropertyDto[] = [];
  @Output() logout = new EventEmitter<boolean>();
  @Output() accountChange = new EventEmitter<Account>();
  @Output() search = new EventEmitter<string>();
  @Output() searchResultSelect = new EventEmitter<PropertyDto>();

  @Input() logoShort = 'assets/logos/velocity/Logoshape.svg';
  @Input() logo = 'assets/logos/velocity/Logotype_Dark.svg';
  @Input() menu: MenuItem[] = [];
  @Input() links: MenuItem[] = [];

  get selectedAccount() {
    return this.user?.accounts.find((account) => account.accountId === this.user.accountId);
  }

  handleAccountChange(account: Account) {
    this.accountChange.emit(account);
  }

  handleLogout() {
    this.logout.emit(true);
  }

  handleSearch(searchKey: string) {
    this.search.emit(searchKey);
  }

  handleSelectSearchResult(value: PropertyDto) {
    this.searchResultSelect.emit(value);
  }
}
