import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PrimeNgFramework } from '@velocity/common';
import { LoginLayoutComponent, PhaseChange, Phases } from '@velocity/login';
import { LoginStore } from './login.store';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

@Component({
  selector: 'login-page',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    LoginLayoutComponent
  ],
  providers: [
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginPageComponent implements OnInit {
  store = inject(LoginStore);
  backgroundVideo = 'assets/videos/landing_page.mp4';
  logo = 'assets/logos/velocity/Logo_Dark.svg';
  layout: 'default' | 'holiday' = 'default';

  ngOnInit(): void {
    this.setLogo();
    this.store.clearFormAndContinue(Phases.Username);
  }

  handlePhaseChange(value: PhaseChange) {
    this.store.onPhaseChange(value);
  }

  setLogo() {
    if (this.isChristmasSeason()) {
      const fileVideoNumber = Math.floor(Math.random() * 2) + 1;
      this.layout = 'holiday';
      this.logo = 'assets/logos/velocity/christmas/logo_xmas.gif';
      this.backgroundVideo = `assets/videos/christmas_landing_videos/landing_video${fileVideoNumber}.mp4`;
    }
  }

  isChristmasSeason() {
    const startMonth = 11;
    const startDay = 5;
    const endMonth = 0;
    const endDay = 3;
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear, startMonth, startDay);
    const endDate = new Date(currentYear + 1, endMonth, endDay);
  
    return dayjs().isBetween(dayjs(startDate), dayjs(endDate));
  }
}
