import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DashboardNavigationLogoComponent } from '../navigation-logo/navigation-logo.component';
import { PrimeNgFramework, PropertyDto, TypographyPComponent } from '@velocity/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';

@Component({
  selector: 'dashboard-navigation-search',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PrimeNgFramework,
    DashboardNavigationLogoComponent,
    TypographyPComponent
  ],
  templateUrl: './navigation-search.component.html',
  styleUrls: ['./navigation-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavigationSearchComponent {
  @Input() placeholder = 'What are you looking for today?';
  @Input() loading = false;
  @Input() results: PropertyDto[] = [];
  @Output() search = new EventEmitter<string>();
  @Output() selectResult = new EventEmitter<PropertyDto>();

  searchField = new FormControl<string>('');

  handleSearch(event: AutoCompleteCompleteEvent) {
    this.search.emit(event.query);
  }

  handleSelect(event: AutoCompleteSelectEvent) {
    this.selectResult.emit((event.value as PropertyDto));
    this.searchField.setValue((event.value as PropertyDto).propertyName);
  }
}
