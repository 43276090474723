<div class="container">
  <div class="forgot-password-instruction">
    <div class="forgot-password">
      <typography-header
        [h]="4"
        [bold]="false"
        label="Forgot password?"
      >
      </typography-header>
    </div>
    <typography-p
      label="Tell us the email you use to sign in to Velocity Properties."
      p="subtitle2"
      class="subtitle-text-color"
    >
    </typography-p>
    <typography-p
      label="We'll send you instructions to reset your password."
      p="subtitle2"
      class="subtitle-text-color"
    >
    </typography-p>
  </div>
  <p-floatLabel>
    <input
      type="email"
      name="email"
      pInputText
      class="full-width"
      [formControl]="forgotPasswordEmailForm"
      required
    />
    <label for="email">Email</label>
  </p-floatLabel>
  <small
    class="p-error block"
    *ngIf="forgotPasswordEmailForm.invalid && forgotPasswordEmailForm.dirty && forgotPasswordEmailForm.hasError('required')"
  >
    Email is required
  </small>
  <small
    class="p-error block"
    *ngIf="forgotPasswordEmailForm.invalid && forgotPasswordEmailForm.dirty && forgotPasswordEmailForm.hasError('email')"
  >
    Email should be a valid email address
  </small>
  <button
    pButton
    [raised]="true"
    [disabled]="disabled || loading" 
    [loading]="loading"
    type="submit"
    (click)="handleResetPassword()"
    label="Reset Password"
  >
  </button>
  <button
    pButton
    label="Return to Sign in"
    [outlined]="true"
    [disabled]="disabled || loading" 
    type="button" 
    (click)="handleBackPhase()"
    >
    </button>
</div>
