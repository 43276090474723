import { CommonModule } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { Account, AppStateService, PrimeNgFramework, PropertyDto } from "@velocity/common";
import { DashboardLayoutComponent } from "./layout/dashboard-layout.component";
import { DashboardNavigationComponent } from "./components/navigation/navigation.component";
import { Router } from "@angular/router";
import { UserIdleService } from "angular-user-idle";
import { Subscription, tap } from "rxjs";
import { Button } from "primeng/button";
import { DashboardUserIdleDialogComponent } from "./components/user-idle-dialog/user-idle-dialog.component";
import { RightActionButtonPayload } from "@velocity/common";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgFramework,
    DashboardLayoutComponent,
    DashboardNavigationComponent,
    DashboardUserIdleDialogComponent
],
  selector: 'dashboard-feature',
  styleUrl: './dashboard.component.scss',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
  private router = inject(Router);
  private userIdleService = inject(UserIdleService);
  private appService = inject(AppStateService);

  @Input() logoShort = 'assets/logos/velocity/Logoshape.svg';
  @Input() logo = 'assets/logos/velocity/Logotype_Dark.svg';
  @Input() propertyDetailsUrl = '/properties/details/:propertyAccountId';

  currentIdleTime = 99;
  idleTimerStart$!: Subscription;
  idleTimeOut$!: Subscription;
  openIdleDialog = false;
  idleDialogSeverity: Button['severity'] = 'primary';

  constructor() {
    this.userIdleService.startWatching();
    this.idleTimerStart$ = this.userIdleService.onTimerStart().subscribe({
      next: (count) => {
        const timeout = this.userIdleService.getConfigValue().timeout ?? 60;
        this.openIdleDialog = true;

        if (this.currentIdleTime > 0) {
          this.currentIdleTime = timeout - count;
        }

        this.idleDialogSeverity = this.currentIdleTime > 6 ? 'primary' : 'warning';
      }
    });

    this.idleTimeOut$ = this.userIdleService.onTimeout().pipe(
      tap(() => {
        this.openIdleDialog = false;
        this.handleLogout();
        this.userIdleService.stopTimer();
        this.userIdleService.stopWatching();
      })
    ).subscribe({
      next: () => {
        this.idleTimerStart$.unsubscribe();
        this.idleTimeOut$.unsubscribe();
      }
    });
  }

  handleAccountChange(account: Account) {
    this.appService.changeCurrentAccount(account.accountId);
  }

  handleActionButton(payload: Omit<RightActionButtonPayload, 'pageIdentifier'>) {
    this.appService.handleRightButtonAction(payload);
  }

  handleSearch(searchKey: string) {
    this.appService.handleSearch(searchKey);
  }

  handleSearchResultSelect(value: PropertyDto) {
    const url = this.propertyDetailsUrl.replace(':propertyAccountId', value.propertyAccountId);
    this.router.navigate([url], {
      queryParams: { tab: 'overview' }
    });
  }

  handleResetIdleTimer() {
    this.userIdleService.resetTimer();
    this.openIdleDialog = false;
  }

  handleLogout() {
    this.appService.logout();
  }

  get state() {
    return this.appService.state();
  }
}
