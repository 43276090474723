import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PrimeNgFramework } from '../../modules/primeng-framework';
import { TypographyPComponent } from '../typography-p/typography-p.component';
import { MediaCarouselItem } from '../../types';

@Component({
  selector: 'media-carousel',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent
  ],
  styleUrl: './media-carousel.component.scss',
  templateUrl: './media-carousel.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MediaCarouselComponent {
  @Input()
  limitWidth = true;

  @Input()
  media: MediaCarouselItem[] = [];

  @Input()
  visibleThumbnails = 5;

  @Input()
  activeIndex = 0;
}
