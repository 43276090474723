import { Component, Input, ViewEncapsulation } from "@angular/core";
import {
  PrimeNgFramework,
  TypographyPComponent,
  TypographyHeaderComponent,
  DerivedHeaderDetails
} from "@velocity/common";
import { DashboardBreadcrumbsComponent } from "../breadcrumbs/breadcrumbs.component";

@Component({
  selector: 'dashboard-details-area-title',
  styleUrl: './details-area-title.component.scss',
  templateUrl: './details-area-title.component.html',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyHeaderComponent,
    TypographyPComponent,
    DashboardBreadcrumbsComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDetailsAreaTitleComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() status: DerivedHeaderDetails['status'];
}
