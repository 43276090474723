import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, Validators, FormControl } from '@angular/forms';
import { PhaseChange } from '../../types/phase-change';
import { Phases } from '../../enums/login-phases';
import { TypographyHeaderComponent } from '@velocity/common';
import { TypographyPComponent } from '@velocity/common';
import { PrimeNgFramework }  from '@velocity/common';

@Component({
  selector: 'login-forgot-password-phase',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    PrimeNgFramework,
    TypographyHeaderComponent,
    TypographyPComponent,
    CommonModule
  ],
  templateUrl: './login-forgot-password-phase.component.html',
  styleUrls: ['./login-forgot-password-phase.component.scss']
})
export class LoginForgotPasswordPhaseComponent implements OnChanges {
  @Input() forgotPasswordEmail = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Output() phaseChange = new EventEmitter<PhaseChange>();

  forgotPasswordEmailForm = new FormControl<string>('');

  constructor() {
    this.forgotPasswordEmailForm.setValue(this.forgotPasswordEmail);
    this.forgotPasswordEmailForm.setValidators([Validators.required, Validators.email]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['forgotPasswordEmail']?.currentValue) {
      this.forgotPasswordEmailForm.setValue(this.forgotPasswordEmail);
      this.forgotPasswordEmailForm.setValidators([Validators.required, Validators.email]);
    }

    if (changes['loading']) {
      const isLoading = changes['loading']?.currentValue;
      this.forgotPasswordEmailForm[isLoading ? 'disable' : 'enable']();
    }
  }

  handleBackPhase() {
    this.phaseChange.emit({
      formData: {
        forgotPasswordEmail: this.forgotPasswordEmailForm.value
      },
      isBack: true,
      next: Phases.Password
    });
  }

  handleResetPassword() {
    this.forgotPasswordEmailForm.markAllAsTouched();
    this.forgotPasswordEmailForm.markAsDirty();

    if (this.forgotPasswordEmailForm.invalid) {
      return;
    }

    this.phaseChange.emit({
      formData: {
        forgotPasswordEmail: this.forgotPasswordEmailForm.value
      },
      next: Phases.RecoveryCode
    });
  }
}
