
<div class="attributes-area" *ngIf="attributes.length">
  <div class="attribute" *ngFor="let attribute of attributes">
    <typography-p
      class="attribute-key"
      [label]="attribute.key"
      p="caption"
    >
    </typography-p>
    <typography-p
      [label]="attribute.value.toString()"
      p="caption"
      *ngIf="attribute.type === 'text'"
    >
    </typography-p>
    <p-avatarGroup
      *ngIf="attribute.type ==='avatar'"
      styleClass="avatargroup"
    >
      <p-avatar
        *ngFor="let avatar of castArray(attribute.value)"
        [label]="getInitials(avatar)"
        shape="circle"
        styleClass="avatar"
        [pTooltip]="avatar"
        tooltipPosition="bottom"
      />
    </p-avatarGroup>
  </div>
</div>
