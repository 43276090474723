<property-details-site-financials-layout
  [asOf]="store.siteFinancials().asOf"
  [tableData]="store.siteFinancials().tableData"
  [tableDataTotal]="store.siteFinancials().tableDataTotal"
  [tableDataLoading]="store.siteFinancials().tableDataLoading"
  [selectedProperties]="store.siteFinancials().selectedProperties"
  [loading]="store.siteFinancials().loading"
  [propertyList]="store.siteFinancials().propertyList"
  [propertyListLoading]="store.siteFinancials().propertyListLoading"
  (propertyListOptionSelect)="handleOptionUpdate($event)"
>
</property-details-site-financials-layout>
