<p-tabMenu
  [model]="items"
  [activeItem]="active"
  [scrollable]="true"
  styleClass="details-area-tab-menu"
>
  <ng-template pTemplate="item" let-item>
    <ng-container>
      <a [routerLink]="item.route" [queryParams]="item.queryParams" class="p-menuitem-link">
        <span *ngIf="item.icon" [class]="item.icon"></span>
        <typography-p [label]="item.label" p="caption"></typography-p>
      </a>
    </ng-container>
  </ng-template>
</p-tabMenu>
