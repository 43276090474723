import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'typography-header',
  standalone: true,
  styleUrl: './typography-header.component.scss',
  templateUrl: './typography-header.component.html',
  imports: [
    CommonModule
  ]
})
export class TypographyHeaderComponent {
  @Input() label = '';
  @Input() bold = true;
  @Input() h: 1 | 2 | 3 | 4 | 5 | 6 = 1;
  @Input() textAlign: 'left' | 'right' | 'center' | 'justify' = 'left';
}
