<div class="key-value-container">
  <div class="key-value-item" *ngFor="let item of items">
    <typography-p
      class="key"
      [label]="item.key"
      p="caption"
    >
    </typography-p>
    <ng-container [ngSwitch]="item.type">
      <typography-p
        [label]="item.value"
        p="caption"
        *ngSwitchCase="'text'"
      >
      </typography-p>
      <a
        class="key-value-link"
        pButton
        [text]="true"
        [plain]="true"
        [href]="item.url"
        *ngSwitchCase="'link'"
      >
        <typography-p
          [label]="item.value"
          p="caption"
        >
        </typography-p>
      </a>
      <typography-p
        [label]="item.value"
        p="caption"
        *ngSwitchDefault
      >
      </typography-p>
    </ng-container>
  </div>
</div>
