import { Component, Input } from '@angular/core';
import { PrimeNgFramework } from '../../modules/primeng-framework';
import { TypographyPComponent } from '../typography-p/typography-p.component';

@Component({
  selector: 'collapsible-card',
  standalone: true,
  imports: [
    PrimeNgFramework,
    TypographyPComponent
  ],
  styleUrl: './collapsible-card.component.scss',
  templateUrl: './collapsible-card.component.html'
})
export class CollapsibleCardComponent {
  @Input() title!: string;
  @Input() icon?: string;
}
