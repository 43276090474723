
<p-progressBar
  *ngIf="loading"
  mode="indeterminate"
  styleClass="financials-table-progressbar"
/>
<p-table
  [value]="tableData"
  [customSort]="true"
  rowGroupMode="subheader"
  groupRowsBy="root"
  [scrollable]="true"
  scrollHeight="80vh"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="financials-table-header" pFrozenColumn>
        <p-button
          [text]="true"
          size="small"
          styleClass="financials-table-header-expand-button"
          (onClick)="toggleAllExpansion()"
        >
          <ng-template pTemplate="icon">
            <span class="material-icons">
              {{ expandedAll ? 'remove' : 'add' }}
            </span>
          </ng-template>
        </p-button>
        <typography-p p="caption" [bold]="true">
          Category Code Description
        </typography-p>
      </th>
      <ng-container *ngFor="let header of tableHeaders">
        <th *ngIf="header.isVisible">
          <typography-p [bold]="true" p="caption">
            {{header.label}}
          </typography-p>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="groupfooter" let-item let-index="rowIndex">
    <tr>
      <td class="financials-table-left-header-border" pFrozenColumn>
        <typography-p p="caption" [bold]="true">
          Total {{item.root}}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalUnderwrittenBudget') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalOriginalBudget') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalRevisedBudget') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalReportingAdjustment') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalProjectedFinalCostBudget') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalRevisedContract') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalSpendToDate') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right" [bold]="true">
          {{ getTotalOf(item.groupId, 'totalCostToComplete') | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
    </tr>
    <ng-container *ngIf="index === (items.length - 1)">
      <tr>
        <td class="financials-table-left-header-border financials-table-grand-total" pFrozenColumn>
          <typography-p p="caption" [bold]="true">
            Grand Total
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalUnderwrittenBudget') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalOriginalBudget') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalRevisedBudget') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalReportingAdjustment') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalProjectedFinalCostBudget') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalRevisedContract') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalSpendToDate') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td class="financials-table-grand-total">
          <typography-p p="caption" textAlign="right" [bold]="true">
            {{getMergedTotalOf('totalCostToComplete') | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr>
      <td class="financials-table-left-header-border" pFrozenColumn>
        <p-button
          [text]="true"
          size="small"
          styleClass="financials-table-cell-expand-button"
          (onClick)="toggleExpansion(item)"
        >
          <span style="display: flex; gap: 4px; align-items: center;">
            <span class="material-icons">
              {{item.expanded ? 'remove' : 'add' }}
            </span>
            <typography-p p="caption">
              {{item.label}}
            </typography-p>
          </span>
        </p-button>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalUnderwrittenBudget | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalOriginalBudget | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalRevisedBudget | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalReportingAdjustment | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalProjectedFinalCostBudget | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalRevisedContract | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalSpendToDate | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
      <td>
        <typography-p p="caption" textAlign="right">
          {{item.totalCostToComplete | number : '1.2-2' | enclose_negative }}
        </typography-p>
      </td>
    </tr>
    <ng-container *ngIf="item.expanded">
      <tr *ngFor="let subItem of item.value">
        <td class="financials-table-children-header-cell" pFrozenColumn>
          <typography-p p="caption">
            {{subItem.code}} - {{subItem.desc}}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.underwrittenBudget | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.originalBudget | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.revisedBudget | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.reportingAdjustment | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.projectedFinalCostBudget | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.revisedContract | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.spendToDate | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
        <td>
          <typography-p p="caption" textAlign="right">
            {{subItem.costToComplete | number : '1.2-2' | enclose_negative }}
          </typography-p>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
