import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PrimeNgFramework } from '@velocity/common';
import { PropertyDetailsStore } from './property-details.store';
import { ActivatedRoute } from '@angular/router';
import { PropertyDetailsOverviewTabComponent } from './tabs/property-overview/property-overview.component';
import { PropertyDetailsFinancialsTabComponent } from './tabs/property-financials/property-financials.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { PropertyDetailsSiteFinancialsTabComponent } from './tabs/site-financials/site-financials.component';

type Params = {
  propertyAccountId: string; 
}

enum PropertyDetailsTab {
  PROPERTY_OVERVIEW = 'overview',
  PROPERTY_FINANCIALS = 'property-financials',
  SITE_FINANCIALS = 'site-financials',
  DOCUMENTS = 'documents'
};

@Component({
  selector: 'property-details-page',
  standalone: true,
  imports: [
    PrimeNgFramework,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    PropertyDetailsOverviewTabComponent,
    PropertyDetailsFinancialsTabComponent,
    PropertyDetailsSiteFinancialsTabComponent
  ],
  providers: [
  ],
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyDetailsComponent implements OnInit {
  activatedRoute = inject(ActivatedRoute);
  store = inject(PropertyDetailsStore);

  tabs = PropertyDetailsTab;

  ngxPermissions = inject(NgxPermissionsService);

  ngOnInit(): void {
    this.store.loadInitialDetails();
    this.activatedRoute.params.subscribe((value) => {
      const { propertyAccountId } = value as Params;

      this.store.setPropertyAccountId(propertyAccountId);
    });
  }

  overview() {
    return this.store.overview();
  }
}
