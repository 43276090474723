<ng-template [ngxPermissionsOnly]="permissions.PROPERTY_FINANCIALS_VIEW">
  <panel-card title="PROPERTY FINANCIALS" icon="receipt_long" *ngIf="!loading">
    <ng-container slot="content">
      <div class="financials-container">
        <typography-header [h]="6" [bold]="false">
          Property Financials as of {{asOf}}
        </typography-header>
        <div class="financials-dropdown-container">
          <typography-p p="body2">
            Jobs Included:
          </typography-p>
          <searchable-dropdown 
            [loading]="propertyListLoading"
            [items]="propertyList"
            [selectedItems]="selectedProperties"
            placeholder="Search Jobs filter"
            (optionSelect)="handlePropertyListOptionSelect($event)"
          >
          </searchable-dropdown>
        </div>
        <financials-table
          [loading]="tableDataLoading"
          [items]="tableData"
          [totalByGroup]="tableDataTotal"
        >
        </financials-table>
      </div>
    </ng-container>
  </panel-card>
  
  <div *ngIf="loading">
    <p-skeleton width="85vw" height="50ch"></p-skeleton>
  </div>
</ng-template>
