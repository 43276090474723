import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PrimeNgFramework } from '../../modules/primeng-framework';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'custom-table',
  standalone: true,
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  imports: [PrimeNgFramework],
})
export class CustomTableComponent<T> {
  @Input() tableData: T[] = [];
  @Input() columns: { field: string; header: string }[] = [];
  @Input() loading: boolean = false;

  // Pagination

  // Setting the paginator to false will hide the paginator in the table
  @Input() paginator: boolean = true;
  @Input() rows: number = 50;
  @Input() rowsPerPageOptions: number[] = [20, 30, 50];
  @Input() first: number = 0;
  @Input() showCurrentPageReport: boolean = true;
  @Input() totalRecords: number = 0;
  @Output() pageChangeEvent = new EventEmitter<number>();

  // Sort
  @Output() customSortEvent = new EventEmitter<SortEvent>();

  // Note -> Passing customSort=true will require you to set custom function  for sorting in the parent component
  // Not passing customSort=true will use the default sorting behavior
  @Input() customSort: boolean = false;
  @Input() isSorted: boolean | null = null;
  @Input() disabledSortColumn: string[] = [];
  @Input() hideColumnFilter: boolean = false;
  @Input() hideColumnSort: boolean = false;

  // Other table options
  @Input() reorderableColumns: boolean = false;
  @Input() scrollable: boolean = false;
  @Input() scrollHeight: string = '';
  @Input() styleClass: string = '';

  private lastSortEvent: SortEvent | null = null;

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;

    const currentPage = event.first / event.rows + 1;

    this.pageChangeEvent.emit(currentPage);
  }

  onCustomSort(event: SortEvent) {
    // Note
    // Emit the sort event only if it has changed
    // Removing this condition will cause infinite emit of event
    if (
      !this.lastSortEvent ||
      this.lastSortEvent.field !== event.field ||
      this.lastSortEvent.order !== event.order
    ) {
      this.lastSortEvent = event;
      this.customSortEvent.emit(event);
    }
  }
}
